import React, { useState,useEffect } from 'react';
import "./ManagerApprovalList.css";
import Loader from '../Loader';
import axios from 'axios';
import { Menu } from '../header/Header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa6";

const ManagerApprovalList = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const [loading,setLoading] = useState(false)
  const labels = ["S.No","AEC ID","Employee Name","Department","Designation","Leave Type"]
  const [leavelist,setLeaveList] = useState([])
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token")
  
  const AtlasEmpID = localStorage.getItem("AtlasEmpID");


  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const response = await axios.get(`${api_url}/request/getapprovalitems/${AtlasEmpID}`);
        const  result= response.data.data;
        console.log("approvallist",response.data.data)
        setLeaveList(result);
      } catch (error) {
        console.log(error);
        setLoading(true)
      } 
      finally {
        setLoading(false); 
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
     
     {loading ? (
      <Loader/>
     ):
     <div className="container-fluid">
          <div className="button-wrapper">
            <div>
            <Menu link="/hrdash"/>
            </div>
      
          </div>
        {/* </div> */}
        <div className="table-view-container-wrapper">
          <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
            Approval List
          </div>
          <table className="table table-hover table-responsive">
        <thead>
          <tr>
            {labels.map((item, index) => (
              <th scope='col' key={index} className="table-header-font-size">{item} </th>
            ))}
          </tr>
        </thead>
        <tbody className='table-body-font-size'>
          {leavelist.map((item, index) => {
            const sno = index + 1;
            return (
              <tr className="py-0" key={index}>
                <td className="py-0">{sno}</td>
                <td className="py-0">{item.AtlasEmpID}</td>
                <td className="py-0">{item.EmpName}</td>
                <td className="py-0">{item.Department}</td>
                <td className="py-0">{item.Designation}</td>
                <td className="py-0">{item.LeaveType}</td>
                <td className="py-0">
                  <Link
                    to="/empoverview"
                     state={{ mode: "edit", reqID: item.RequestID }}
                  >
                    <FaEye/>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
        </div>
      </div>
     }
    </>
  )
}

export default ManagerApprovalList;