import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Menu } from "../../header/Header";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";

const PayrollManagement = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (!token) {
    navigate("/");
  }
  
  const [PayrollHeader, setPayrollHeader] = useState([]);

  useEffect(() => {
    axios
      .get(`${api_url}/payroll/header/GetPayrollDetails`)
      .then((res) => {
        const response = res.data.list;
        // console.log("response", response);
        setPayrollHeader(response);
      })
      .catch((err) => console.log(err));
  }, []);

  const labels = ["S.No", "Month", "Payslip Generated", "Status",""];


  // console.log(PayrollHeader);
  
  // const data = [...PayrollHeader];

 
  return (
    <>
      <div className=" my-1 mx-5 p-1">
        <div className="d-block overflow-auto ">
          <div className="d-inline-block align-middle">
            <Menu link="/hrdash" />
          </div>

          <div className="d-inline-block p-1 float-xxl-end float-xl-end float-md-end float-sm-end">
            <Link to="/attendance" >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<AddIcon />}
              >
                Create Payroll
              </Button>
            </Link>
          </div>
        </div>

        <div className="table-view-container-wrapper">
          <div className="p-2 mt-2 mb-4 d-flex shadow justify-content-center blockquote">
            Payroll Management
          </div>
          <div className="search-bar d-flex justify-content-start  d-flex">
            <div className="search-dept">
              <select
                name="department"
                className="form-input-select"
                id="department"
                aria-label="Small select example"
                // onChange={(e) => {
                //   handleSearchDropdownChange(e);
                // }}
              >
                <option value="" defaultValue={""}>
                  2024
                </option>
                {/* {departments.map((dept) => (
                <option key={dept.DeptID} value={dept.DepartmentName}>
                  {dept.DepartmentName}
                </option>
              ))} */}
              </select>
            </div>
          </div>
          <div className="">
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  {labels.map((item, index) => (
                    <th key={index} scope="col">{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {PayrollHeader.map((item, index) => {
                  return (
                    // let PayslipStatus = item.PayslipInd;
                    // if(PayslipStatus == 0){PayslipStatus = "-";}
                    // else if(PayslipStatus == 1){PayslipStatus = "Payslip Generated";}

                    // PRStatusInd == 0 >>> Payroll In Progress.
                    //else if 1 >>> Payroll Completed

                    // August

                    <tr className="py-0" key={index}>
                      <td className="py-0">{item.PayrollID}</td>
                      <td className="py-0">{item.PRMonth}</td>
                      <td className="py-0">{item.PayslipInd}</td>
                      <td className=" py-0 ">{item.PRStatusInd}</td>
                      <td className="py-0" >
                        <Link
                          to="/attendance"
                          state={{
                            PayrollID: item.PayrollID,
                            PRYear: item.PRYear,
                            PRMonth: item.PRMonth,
                          }}
                        >
                          <EditIcon />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayrollManagement;
