import React,{useState,useEffect} from 'react';
import "./HRFormsGrid.css";
import { Link,useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import axios from 'axios';
import { Menu } from '../../header/Header';
import Loader from "../../Loader";

import { FaEye } from "react-icons/fa6";

const HRFormsGrid = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (!token) {
    navigate("/");
  }
  const [loading,setLoading] = useState(true);
  const [formList,setFormList] = useState([]);
  const labels = ["S.No","File Name","File Number","Modified Date"];
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${api_url}/hr/getformslist`);
        const formlist = res.data.list;
        setFormList(formlist);
      } catch (error) {
        console.log(error);
        setLoading(true)
      } 
      finally {
        setLoading(false); 
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
    
    {loading ? (
     <Loader/>
    ):
    <div className="container-fluid">
         <div className="button-wrapper">
           <div>
           <Menu link="/hrdash"/>
           </div>
           <div>
             <Link to="/hrforms" state={{ mode: "add" }}>
               <Button
                 type="submit"
                 variant="contained"
                 color="primary"
                 endIcon={<AddIcon />}
               >
                 Add form
               </Button>
             </Link>
           </div>
         </div>
       {/* </div> */}
       <div className="table-view-container-wrapper">
         <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
           Forms List
         </div>
         <table className="table table-hover table-responsive">
       <thead>
         <tr>
           {labels.map((item, index) => (
             <th scope='col' key={index} className="table-header-font-size">{item} </th>
           ))}
         </tr>
       </thead>
       <tbody className='table-body-font-size'>
         {formList.map((emp, index) => {
           const sno = index + 1;
           return (
             <tr className="py-0" key={index}>
               <td className="py-0">{sno}</td>
               <td className="py-0">{emp.AtlasEmpID}</td>
               <td className="py-0">{emp.FirstName+"  "+emp.LastName}</td>
               <td className="py-0">{emp.Department}</td>
               <td className="py-0">{emp.Designation}</td>
               <td className="py-0">{emp.ReportingManager}</td>
               <td className="py-0">{emp.OfficialMobileNumber}</td>
               <td className="py-0">
                 <Link
                   to="/empoverview"
                    state={{ mode: "edit", empID: emp.AtlasEmpID }}
                 >
                   <FaEye/>
                 </Link>
               </td>
             </tr>
           );
         })}
       </tbody>
     </table>

       </div>
     </div>
    }
     
   </>
    
  )
}

export default HRFormsGrid