import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./EmployeeOverview.css";
import axios from "axios";
import Profilepicicon from "../../../assets/Profile-picture.jpg";
import { Menu } from "../../header/Header";
import { Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import Loader from "../../Loader";

const EmployeeOverview = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { mode, empID } = location.state || {};
  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [data,setData] = useState({})
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      navigate("/");
      }  
        getUserDetails(empID);
  }, []);

  const HandleImageClick = () => {
    inputRef.current.click(); // Trigger file input click
  };

  const HandleImageChange = async (event) => {
    const file = event.target.files[0];
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (file) {
      if (validTypes.includes(file.type)) {
        setImage(URL.createObjectURL(file)); // Display selected image
        await uploadProfilePicture(file);
      }
      else{
        event.target.value = null;
        alert('Invalid file type. Please select an image file (PNG, JPEG, JPG).');
      }
    }
    else{
      alert("Please re-upload the image");
    }
  };

  const divstyle = { width: "80%" };
  const divstyle1 = { width: "50%" };

  const getUserDetails = async (eid) => {
    try {
      const res = await axios.get(`${api_url}/emp/getoverview/${eid}`);
      setImage(res.data.imageUrl);
      setData(res.data.list[0]);
    }
    catch(err){
      console.log(err);
    }
    finally{
      setLoading(false);
    }
  }

  const uploadProfilePicture = async (file) => {
    try {
      const formData = new FormData();
      formData.append('Profile-Picture', file);
      formData.append('id', empID); // Assuming empID is needed on the server side

      const response = await axios.post(`${api_url}/emp/uploadprofilepicture/${empID}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };


  return (
    <>
    {loading?<Loader/>:
    <div className="employee-dashboard container g-3">
    <div className="d-flex justify-content-between mb-4">
      <Menu link="/empmgmt" />
      <div className="">
      <Link to="/empdocs" className="hr-documents-button" state={{ mode: mode, empID: empID }}>
          <Button 
            type="submit"
            variant="contained"
            color="secondary"
            endIcon={<DescriptionIcon />}
          >
            Documents
          </Button>
        </Link>
        <Link to="/empform" state={{ mode: mode, empID: empID }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Link>
      </div>
    </div>

    <div className="row g-3">
      <div className="col-2 box-div">
      <div onClick={HandleImageClick}>
  <img
    src={image || Profilepicicon} // Display selected image or default
    className="profile-img"
    alt="profile-img"
  />
  <input
    type="file"
    className="d-none p-0"
    id="inputprofilepic"
    onChange={HandleImageChange}
    ref={inputRef}
    name="profilepic"
    accept="image/png, image/jpeg, image/jpg"
  />
</div>
      </div>
      <div className="col-10 details-div">
        <div className="row ">
          <div className="col-4 col-md-2">
            <p className="form-label">First Name </p>
          </div>
          <div className="col-4 col-md-3">
            {/* <input type="text" className="form-input-text" name="fname" id="fname" placeholder="Sample Text" disabled/> */}
            <p className="fs-7 m-0">{data.FirstName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <p className="form-label">Last Name </p>
          </div>
          <div className="col-3">
            {/* <input type="text" name="lname" className="form-input-text" id="lname" placeholder="Sample Text" disabled /> */}
            <p className="fs-7 m-0">{data.LastName}</p>
          </div>
        </div>
        <div className="row ">
          <div className="col-2">
            <p className="form-label">Department</p>
          </div>
          <div className="col-3">
            {/* <input type="text" name="department" className="form-input-text" id="department"  placeholder="Sample Text"/> */}
            <p className="fs-7 m-0">{data.Department}</p>
          </div>
        </div>
        <div className="row ">
          <div className="col-2">
            <p className="form-label">Designation</p>
          </div>
          <div className="col-3">
            {/* <input type="text" name="designation" className="form-input-text" id="designation" placeholder="Sample Text" /> */}
            <p className="fs-7 m-0">{data.Designation}</p>
          </div>
        </div>
        <div className="row ">
          <div className="col-2">
            <p className="form-label">Email ID</p>
          </div>
          <div className="col-3">
            {/* <input type="email" className="form-input-email" name="emailid"  id="emailid" placeholder="Sample Text" disabled /> */}
            <p className="fs-7 m-0">{data.OfficialEmailID}</p>
          </div>
        </div>
        <div className="row ">
          <div className="col-2">
            <p className="form-label">Mobile Number</p>
          </div>
          <div className="col-3">
            {/* <input type="number" className="form-input-number" name="mobilenumber" id="mobilenumber" min={0} onKeyDown={handleKeyDown} onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} /> */}
            <p className="fs-7 m-0">{data.OfficialMobileNumber}</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row g-3">
      <div className="col-12">
        <div className="row g-3 mt-2">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="emp-dash-card">
              <div className="card-category">Length of Employment</div>
              <div className="card-content">
                <div className="card-title"> 9 Years</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="emp-dash-card">
              <div className="card-category">Absences</div>
              <div className="card-content">
                <div className="card-title"> 7 Days</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="p-2 border  emp-dash-card">
              <div className="card-category">Remaining Annual Leave</div>
              <div className="card-content">
                <div className="card-title"> 22 Days</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="p-2 border  emp-dash-card">
              <div className="fs-5 mb-2">Leave Schedule</div>
              <div className="mt-1">
                <p>AL - 15-08-2024 - 15-09-2024</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 mt-2">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="p-2 border  emp-dash-card">
              <div className="fs-5">Trainings Attended</div>
              <div className="mt-1">
                <p>HSE</p>
                <p>H2S</p>
                <p>ERP Training</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="p-2 border emp-dash-card">
              <div className="fs-5 fw-semibold">Active Jobs</div>
              <div className="mt-1">
                <p>Project 1</p>
                <p>Project 2</p>
                <p>Project 3</p>
                <p>Project 8</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="p-2 border emp-dash-card">
              <div className="fs-5 fw-semibold">Skill set rating</div>
              <div className="">
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <p>AutoCAD</p>
                  </div>

                  <div className="progress w-100">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-label="Example with label"
                      style={divstyle}
                      aria-valuenow="80"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      80%
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <p>SolidWorks</p>
                  </div>
                  <div className="progress w-100">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-label="Example with label"
                      style={divstyle1}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      50%
                    </div>
                  </div>
                </div>
                {/* <div>
                  <div>
                    <p>Civil 3D</p>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-label="Example with label"
                      style={divstyle}
                      aria-valuenow="37"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      37%
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="p-2 border emp-dash-card">
              <div className="fs-5 fw-semibold">
                Language known / Fluency
              </div>
              <div className="">
                <p>Arabic</p>
                <p>English</p>
                <p>Hindi</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>}
      
    </>
  );
};

export default EmployeeOverview;
