import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Menu } from "../../../header/Header";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import "./LeaveRequestList.css";
import { FaEye } from "react-icons/fa6";

import Loader from "../../../Loader";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LeaveRequestList = () => {
  const [loading,setLoading] = useState(true);
  const [leaveList, setLeaveList] = useState([]);
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const AtlasEmpID = localStorage.getItem("AtlasEmpID")
  const token = localStorage.getItem("token");
  if (!token) {
    navigate("/");
  }
  const labels = [
    "S.No","LeaveType","LeaveStarts","LeaveEnds","SubmittedDate","ReportingManagerApprovedDate","HRApprovedDate",""
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${api_url}/requests/leave-requests/${AtlasEmpID}`);
        const leavelist = res.data.list;
        setLeaveList(leavelist);
        setLoading(false);
               
      } catch (error) {
        console.log(error);
        toast.error( "Failed to retrieve data. Please contact administrator", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className:"custom-toast"
        });
        setLoading(true)
      } 
      finally {
        setLoading(false); 
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
    
     {loading ? (
      <Loader/>
     ):
     <div className="container-fluid">
          <div className="button-wrapper">
            <div>
            <Menu link="/hrdash"/>
            </div>
            <div>
              <Link to="/requests/leave-request-form" state={{ mode: "add" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<AddIcon />}
                >
                  Request Leave
                </Button>
              </Link>
            </div>
          </div>
        <div className="table-view-container-wrapper">
          <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
            Leave Request List
          </div>
          <table className="table table-hover table-responsive">
        <thead>
          <tr>
            {labels.map((item, index) => (
              <th scope='col' key={index} className="table-header-font-size">{item} </th>
            ))}
          </tr>
        </thead>
        <tbody className='table-body-font-size'>
          {leaveList.map((item, index) => {
            const sno = index + 1;
            return (
              <tr className="py-0" key={index}>
                <td className="py-0">{sno}</td>
                <td className="py-0">{item.LeaveType}</td>
                <td className="py-0">{item.LeaveStarts}</td>
                <td className="py-0">{item.LeaveEnds}</td>
                <td className="py-0">{item.SubmittedDate}</td>
                <td className="py-0">{item.ReportingManagerApprovedDate}</td>
                <td className="py-0">{item.HRApprovedDate}</td>
                <td className="py-0">{item.StatusInd }</td>
                <td className="py-0">
                  <Link
                    to="/requests/leave-request-form"
                     state={{ mode: "edit", reqID: item.RequestID }}
                  >
                    <FaEye/>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
        </div>
      </div>
     }
      
    </>
  )
}

export default LeaveRequestList;

