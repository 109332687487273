import { React, useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "../../header/Header";
import axios from "axios";

import Button from "@mui/material/Button";
import "./Dependent.css";

import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";

import Profilepicicon from "../../../assets/Profile-picture.jpg";

// import DisableNumberInputScroll from "../../CustomHooks/DisableNumberInputScroll";

// import "./Formcomponent.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dependent = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const token = localStorage.getItem("token");
  const [image, setImage] = useState("");
  const [dependent, setDependent] = useState({});

  const { mode, AtlasEmpID, DepID } = location.state || {};


  useEffect(()=> {
    if (!token) {
    navigate("/");
    }    
  },[])


  const handleCreate = (id) => {
    let temp = { ...dependent, AtlasEmpID: id };
    axios
      .post(`${api_url}/dependent/createdependent`, temp)
      .then((response) => {
        console.log("Dependent details created Successfully", response);
        navigate("/empmgmt");
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };


  useEffect(() => {
    if (mode === "edit") {
      handleEdit(DepID);
    }
    // else if (mode === "add") {
    //   getDepartments();
    // }
  }, []);

  const handleEdit = async (id) => {
    try {
      const res = await axios.get(
        `${api_url}/dependent/getdependentdetails/${id}`
      );
    //   console.log("response from get request", res.data[0]);
      setDependent(res.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdate = (id) => {
    axios
      .put(
        `${api_url}/dependent/updatedependentdetails/${id}`,
        dependent
      )
      .then((response) => {})
      .catch((error) => {
        console.error("There was an error updating!", error);
      });
      navigate("/empmgmt");
  };

  const handleDelete = async (id) => {
    await axios
      .delete(
        `${api_url}/dependent/deletedependentdetails/${id}`
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    navigate("/empmgmt");
    toast.error("Dependent Deleted!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "light",
    });
  };

  const handleSubmit = (eve) => {
    eve.preventDefault();
    mode === "add" ? addDependent(AtlasEmpID) : EditDependent(DepID);
  };

  const addDependent = (id) => {
    handleCreate(id);
    toast.success("Dependent Added Successfully!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "light",
    });
  };
  const EditDependent = (id) => {
    handleUpdate(id);
    toast.success("Dependent Updated Successfully!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "light",
    });
  };

  const HandleImageChange = (event) => {
    const file = event.target.files[0];
    console.log(file);

    setImage(event.target.files[0]);
  };

  const HandleImageClick = () => {
    inputRef.current.click();
  };

  const handleInputChanges = (event, field) => {
    let temp = { ...dependent };
    temp[field] = event.target.value;
    setDependent(temp);
  };
//   console.log("dependent", dependent);
  const RedContainedButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.error.main), // Sets text color to contrast with red
    backgroundColor: theme.palette.error.main, // Sets background color to red
    "&:hover": {
      backgroundColor: theme.palette.error.dark, // Sets background color to darker red on hover
    },
  }));

  return (
    <>
      <div className="Header-wrapper-form">
        {/* <Header /> */}
      </div>
      <div className="emp-form-menu-button">
        <Menu link="/empmgmt" />
      </div>
      <div className="emp-form d-block">
        {/* Form Starts */}
        <form
          className=""
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="button">
            {mode === "edit" ? (
              <RedContainedButton
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  handleDelete(DepID);
                }}
                className="m-2"
              >
                Delete
              </RedContainedButton>
            ) : (
              ""
            )}
            <Button type="submit" variant="contained" color="primary">
              Save Details
            </Button>
          </div>

          <div className="container p-3 form-wrapper border border-secondary">
            <div className="row sub-wrapper ">
              <h5 className="sub-wrapper-heading">Dependent Details</h5>
              <div className="col-6 dependent-form">
                <label className="form-label" htmlFor="dependentname">
                  Dependent Name<span className="required"> *</span>
                </label>
                <input
                  type="text"
                  className="form-input-text"
                  id="dependentname"
                  placeholder=""
                  value={dependent.DependentName || ""}
                  maxLength="150"
                  onChange={(e) => {
                    handleInputChanges(e, "DependentName");
                  }}
                  required
                />

                <label className="form-label" htmlFor="dependenttype">
                  Dependent Type<span className="required"> *</span>
                </label>
                <select
                  onChange={(e) => {
                    handleInputChanges(e, "DependentType");
                    //   handleDepartmentChange(e);
                  }}
                  value={dependent.DependentType || ""}
                  name="dependenttype"
                  className="form-input-select"
                  id="dependenttype"
                  aria-label=".form-select-sm example"
                  required
                >
                  <option value="">Select Dependent Type</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Child">Child</option>
                </select>

                <label className="form-label" htmlFor="nationality">
                  Nationality<span className="required"> *</span>
                </label>
                <select
                  name="nationality"
                  className="form-input-select"
                  aria-label=".form-select-sm example"
                  onChange={(e) => {
                    handleInputChanges(e, "Nationality");
                  }}
                  value={dependent.Nationality || ""}
                  required
                >
                  <option value="">Select Nationality</option>
                  <option value="Omani">Omani</option>
                  <option value="Indian">Indian</option>
                  <option value="Irani">Irani</option>
                  <option value="Pakistani">Pakistani</option>
                  <option value="Filipino">Filipino</option>
                  <option value="Bangladeshi">Bangladeshi</option>
                </select>

                <label className="form-label" htmlFor="dob">
                  Date of Birth
                </label>
                <input
                  type="date"
                  className="form-input-date"
                  id="dob"
                  placeholder=""
                  value={dependent.DOB || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "DOB");
                  }}
                />

                <label className="form-label" htmlFor="visanumber">
                  Visa Number
                </label>
                <input
                  type="text"
                  className="form-input-text"
                  id="visanumber"
                  placeholder=""
                  maxLength="20"
                  value={dependent.VisaNumber || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "VisaNumber");
                  }}
                />

                <label className="form-label" htmlFor="visaissuedate">
                  Visa Issue Date
                </label>
                <input
                  id="visaissuedate"
                  className="form-input-date"
                  type="date"
                  value={dependent.VisaIssuedDate || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "VisaIssuedDate");
                  }}
                />

                <label className="form-label" htmlFor="visaexpirydate">
                  Visa Expiry Date
                </label>
                <input
                  id="visaexpirydate"
                  className="form-input-date"
                  type="date"
                  value={dependent.VisaExpiryDate || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "VisaExpiryDate");
                  }}
                />

                <label className="form-label" htmlFor="passportnumber">
                  Passport Number
                </label>
                <input
                  type="text"
                  className="form-input-text"
                  id="passportnumber"
                  placeholder=""
                  maxLength="20"
                  value={dependent.PassportNumber || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "PassportNumber");
                  }}
                />
                <label className="form-label" htmlFor="passportissueplace">
                  Passport Issued Place
                </label>
                <input
                  type="text"
                  className="form-input-text"
                  id="PassportIssuedPlace"
                  placeholder=""
                  maxLength="20"
                  value={dependent.PassportIssuedPlace || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "PassportIssuedPlace");
                  }}
                />

                <label className="form-label" htmlFor="passportissueddate">
                  Passport Issued Date
                </label>
                <input
                  type="date"
                  className="form-input-date"
                  id="passportissueddate"
                  placeholder=""
                  value={dependent.PassportIssuedDate || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "PassportIssuedDate");
                  }}
                />

                <label className="form-label" htmlFor="passportexpiry">
                  Passport Expires on
                </label>
                <input
                  type="date"
                  className="form-input-date"
                  id="passportexpiry"
                  placeholder=""
                  value={dependent.PassportExpiryDate || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "PassportExpiryDate");
                  }}
                />

                <label className="form-label" htmlFor="medicalnumber">
                  Medical Number
                </label>
                <input
                  type="text"
                  className="form-input-text"
                  id="medicalnumber"
                  placeholder=""
                  maxLength="20"
                  value={dependent.MedicalNumber || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "MedicalNumber");
                  }}
                />

                <label className="form-label" htmlFor="medicalissuedate">
                  Medical Issue Date
                </label>
                <input
                  id="medicalissuedate"
                  className="form-input-date"
                  type="date"
                  value={dependent.MedicalIssueDate || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "MedicalIssueDate");
                  }}
                />

                <label className="form-label" htmlFor="medicalexpiry">
                  MedicalExpiry
                </label>
                <input
                  id="medicalexpiry"
                  className="form-input-date"
                  type="date"
                  value={dependent.MedicalExpiry || ""}
                  onChange={(e) => {
                    handleInputChanges(e, "MedicalExpiry");
                  }}
                />
              </div>
              <div className="col-2">
                <div onClick={HandleImageClick}>
              {image ? (
                      <img
                        src={
                          mode === "add" ? URL.createObjectURL(image) : image
                        }
                        className="profile-img"
                        alt="profile-img"
                      />
                    ) : (
                      <img
                        src={Profilepicicon}
                        className="profile-img"
                        alt=""
                      />
                    )}
                     <input
                      type="file"
                      className="d-none p-0"
                      id="inputprofilepic"
                      onChange={HandleImageChange}
                      ref={inputRef}
                      name="profilepic"
                    />
                    </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Dependent;
