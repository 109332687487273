import React, { useState, useEffect } from "react";
import { Menu } from "../../header/Header";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { FaFolder } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import EditIcon from "@mui/icons-material/Edit";

import SearchIcon from "@mui/icons-material/Search";
import "./EmployeeDocuments.css";
import Loader from "../../Loader";
import { FaEye } from "react-icons/fa6";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmployeeDocuments = () => {
  const location = useLocation();
  const api_url = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const { mode, empID } = location.state || {};
  const navigate = useNavigate();
  const [documentList, setDocumentList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

 


  
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    const GetDocumentsList = () => {
      setLoading(true);
      try {
        axios.get(`${api_url}/emp/getdocumentlist/${empID}`).then((res) => {
          //  console.log("res",res);
          setDocumentList(res.data);
        });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    GetDocumentsList();
  }, []);

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };

  const SearchRecords = async () => {
    const payload = {
      searchText: searchText,
    };
    await axios
      .post(`${api_url}/recruitment/cv/searchrecords`, payload)
      .then((res) => {
        setDocumentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleView = async (Docid, AtlasEmpID) => {
  //   const response = await axios.post(
  //     "https://atlas-om.app:30000/api/emp/viewdocument",
  //     { Docid, AtlasEmpID }
  //   );
  //   console.log(response);
  //   const url = response.data;
  //   console.log(url);
  //   window.open(url, "_blank");
  // };

  const handleView = async (Docid, AtlasEmpID) => {
    try {
      const response = await axios.post("https://atlas-om.app:30000/api/emp/viewdocument", { Docid, AtlasEmpID });
      const url = response.data;
      window.open(url, "_blank");
    } catch (error) {
      toast.error( "Failed to retrieve document. Please contact administrator", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className:"custom-toast"
      });
    
    }
  };
  
  const elements = [];
  let CurrentCategory = "";
  for (let index = 0; index < documentList.length; index++) {
    const item = documentList[index];
    const documentCategory = item.DocumentCategory;

    // Check if the category has changed
    if (CurrentCategory !== documentCategory) {
      // Add a new category header if the category has changed
      elements.push(
        <div key={`category-${documentCategory}`} className="category-header">
          <FaFolder className="folder-icon" /> {documentCategory}
        </div>
      );
      CurrentCategory = documentCategory; // Update the current category
    }

    // Add the document item
    elements.push(
      <div key={item.DocumentID} className="employee-document-item-wrapper">
        <div className="employee-document-item">
          <FaFilePdf className="pdf-icon" />
          <div className="employee-document-name">{item.DocumentName}</div>
          <div className="icon-container ms-4">
              <FaEye
                  className="view-employee-document"
                  onClick={() => handleView(item.DocumentID, empID)}
              />
              <Link
                  to="/empdocform"
                  state={{
                      mode: "edit",
                      DocumentID: item.DocumentID,
                      EmpID: empID,
                  }}
              >
                  <EditIcon className="edit-icon-emp-doc-form" />
              </Link>
          </div>
          </div>
      </div>
  );
  
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="my-1 mx-5 p-1">
          <div className="d-block">
            <div className="d-inline-block align-middle">
              <Menu link="/empmgmt" />
            </div>
            <div className="d-inline-block p-1 float-xxl-end float-xl-end float-md-end float-sm-end">
              <Link to="/empdocform" state={{ mode: "add", EmpID: empID }}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                >
                  Add Document
                </Button>
              </Link>
            </div>
          </div>
          <div className="table-view-container-wrapper">
            <div className="p-2 mt-2 mb-4 d-flex shadow justify-content-between align-items-center">
              <div className="header-div">Employee Document List</div>
              <div className="search-box-div">
                <input
                  type="text"
                  className="form-control search-box-input"
                  placeholder="Search docs"
                  onChange={(e) => handleSearch(e)}
                />
                <button onClick={SearchRecords}>
                  <SearchIcon className="search-box-icon" />
                </button>
              </div>
            </div>
              <div className="ms-5">{elements}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeDocuments;
