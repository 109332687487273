import { Route, Routes, useLocation } from "react-router-dom";
import { useState } from "react";
// import Users from "./Users";
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar";
import Login from "./components/login/Login";
import HRDashboard from "./components/HR/HRDashboard";
import EmployeeManagement from "./components/HR/Employees/EmployeeManagement";
import EmployeeForm from "./components/HR/Employees/EmployeeForm";
import ITManagement from "./ITManagement";
import CircularForm from "./components/HR/Circulars/CircularForm";
import "./App.css";
import Circulars from "./components/HR/Circulars/Circulars";
import Dependent from "./components/HR/Dependent/Dependent";
import PayrollManagement from "./components/HR/Payroll/PayrollManagement";
import Attendance from "./components/HR/Payroll/Attendance";
import Earning from "./components/HR/Payroll/Earning";
import Deduction from "./components/HR/Payroll/Deduction";
import FinalSheet from "./components/HR/Payroll/FinalSheet";
import ApplicantDatabase from "./components/HR/Recruitment/ApplicantDatabase";
import ResumeCV from "./components/HR/Recruitment/ResumeCV";
import EmployeeOverview from "./components/HR/Employees/EmployeeOverview";
import HRDocumentsForm from "./components/HR/HRDocuments/HRDocumentsForm";
import HRDocuments from "./components/HR/HRDocuments/HRDocuments";

import EmployeeDocuments from "./components/HR/Employees/EmployeeDocuments";
import EmpDocumentForm from "./components/HR/Employees/EmpDocumentForm";

import HRForms from "./components/HR/HRUtilities/HRForms";
import HRFormsGrid from "./components/HR/HRUtilities/HRFormsGrid";
import GeoLocation from "./components/geolocation/GeoLocation";
import UserSignInForm from "./components/user/UserSignInForm";
import TimeManagement from "./components/user/TimeManagement";

import RequestDashboard from "./components/commonModules/requests/RequestDashboard";
import LeaveRequestList from "./components/commonModules/requests/LeaveRequest/LeaveRequestList";
import LeaveRequestForm from "./components/commonModules/requests/LeaveRequest/LeaveRequestForm";
import ManagerApprovalList from "./components/approvals/ManagerApprovalList";


function App() {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [reportingManager,setReportingManager] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const toggleSidebarFalse = () =>{
    setSidebarVisible(false);
  }

  // const toggleReportingManager = () =>{
  //   setReportingManager(true);
  // }
  const toggleReportingManager = (value) => {
    setReportingManager(value);
  }

  

  const loginpath = location.pathname === "/";

  // console.log("reporting manager",reportingManager)

  
  return (
    <div className={`App ${!sidebarVisible ? "sidebar-hidden" : ""} ${loginpath ? 'login-page' : ''}`}>
      {!loginpath && (
        <>
          <Header onToggle={toggleSidebar} sidebarVisible={sidebarVisible} reportingManager={reportingManager} onToggleReportingManager={setReportingManager} />
          <Sidebar isVisible={sidebarVisible} onClose={toggleSidebar} />
        </>
      )}
      <div className="content">
        <Routes>
          <Route exact path="/hrdash" element={<HRDashboard  onToggle={toggleSidebarFalse}/>} />
          <Route exact path="/itmgmt" element={<ITManagement />} />
          <Route exact path="/" element={<Login onToggle={toggleReportingManager}/>} />
          {/* <Route exact path="/" element={<Login onToggle={toggleReportingManager} />} /> */}
          <Route exact path="/empmgmt" element={<EmployeeManagement />} />
          <Route exact path="/empoverview" element={<EmployeeOverview />} />
          <Route exact path="/empform" element={<EmployeeForm />} />
          <Route exact path="/circulars" element={<Circulars />} />
          <Route exact path="/cir-form" element={<CircularForm />} />
          <Route exact path="/dependent" element={<Dependent/>}/>
          <Route exact path="/payroll" element={<PayrollManagement/>}/>
          <Route exact path="/attendance" element={<Attendance/>}/>
          <Route exact path="/earning" element={<Earning/>}/>
          <Route exact path="/deduction" element={<Deduction/>}/>
          <Route exact path="/finalsheet" element={<FinalSheet/>}/>
          <Route exact path="/cvdb" element={<ApplicantDatabase/>}/>
          <Route exact path="/cvform" element={<ResumeCV/>}/>
          <Route exact path="/dependent" element={<Dependent/>}/>
          <Route exact path="/hrdocs" element={<HRDocuments/>}/>
          <Route exact path="/hrdocform" element={<HRDocumentsForm/>} />
          <Route exact path="/empdocform" element={<EmpDocumentForm/>} />
          <Route exact path="/empdocs" element={<EmployeeDocuments/>} />
          <Route exact path="/hrforms" element={<HRForms/>} />
          <Route exact path="/hrformsmgmt" element={<HRFormsGrid/>} />
          <Route exact path="/geolocation" element={<GeoLocation/>} />
      {/* User Functions ----   Register Forms */}
          <Route exact path="/user-sign-in/reg-form" element = {<UserSignInForm/>} />
          <Route exact path="/user-sign-in/punchdetails" element = {<TimeManagement/>} />
          <Route exact path="/timesheetMgmt" element = {<TimeManagement/>} />
          <Route exact path="/requestdashboard" element = {<RequestDashboard/>} />
          <Route exact path="/requests/leave-request-list" element = {<LeaveRequestList/>} />
          <Route exact path="/requests/leave-request-form" element = {<LeaveRequestForm/>} />
          <Route exact path="/requests/manager-leave-approvals" element = {<ManagerApprovalList/>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;