import React,{useState,useEffect} from 'react';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { Button } from "@mui/material";
import "./LeaveRequestForm.css";
import Loader from '../../../Loader';
import axios from 'axios';
import { styled } from "@mui/material/styles";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LeaveRequestForm = () => {
  const AtlasEmpID = localStorage.getItem("AtlasEmpID");
  const [requestdetails, setRequestDetails] = useState({
    AtlasEmpID:AtlasEmpID
  });
  const [noofdays,setNoofdays] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { mode, reqID } = location.state || {};
  const api_url = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getbasicdetails();
    if (mode === "edit") {
      getrequestDetails(reqID);
    } else if (mode === "add") {
      getDepartments();
      setLoading(false);
    }
  }, [mode, reqID]);

  const handleInputChanges = (event, field) => {
    let type = event.target.type;
    let val = event.target.value;
    if((type==="date")&& (val==="")){
      val = null;
    }
    let request = { ...requestdetails }; 
    request[field] = val 
    setRequestDetails(request); 
  };

  const CalculateDateDifference = (startdate,enddate) =>{
    if (startdate && enddate) {
      const startDate = new Date(startdate);
      const endDate = new Date(enddate);
      const timeDifference = endDate - startDate;
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return daysDifference;
    }
    return null;
  }

  const difference = CalculateDateDifference(requestdetails.LeaveStarts, requestdetails.LeaveEnds);


  
  const InsertRequestDetails = async () => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const year = today.getFullYear();
    const date = today.getDate().toString().padStart(2, '0');
    const currentDate = `${year}-${month}-${date}`;
    let temp = { ...requestdetails, SubmittedDate: currentDate };
  
    try {
      const response = await axios.post(
        `${api_url}/requests/createleaverequest`,
        temp, 
        {
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      );
      console.log("Request details created successfully", response.data.success);
      return response.data.success; 
    } catch (error) {
      console.log("Error:", error);
      return false; 
    }
  };
  

  const updateRequest = async (eid) => {
    try {
      const response = await axios.put(`${api_url}/request/updaterequestdetails/${eid}`, requestdetails);
      return response.data.success; 
    } catch (error) {
      console.error("There was an error updating the Request!", error);
      return false; 
    } finally {
      navigate("/requests/leave-request-list");
    }
  };


  const getDepartments = async () => {
    try {
      const response = await axios.get(`${api_url}/emp/GetDepartments`);
      const result = response.data.results;
      console.log("Departments from API:", result);
      setDepartments(result);
      return result;
    } catch (error) {
      console.error("Error fetching departments:", error);
      return []; // Return an empty array in case of error
    }
  };
  

  const getDesignations = (e) => {
    axios
      .get(`${api_url}/emp/GetDesignations/${e}`)
      // .get(`${api_url}/emp/GetDesignations/${e}`)
      .then((response) => {
        setDesignations(response.data);
      })
      .catch((err) => {
        console.error("Error fetching Desingations", err);
      });
  };


  // const handleDepartmentChange = (e) => {
  //   let value = e.target.value;
  //   if (value == "") {
  //     setSelectedDept(value);
  //     setDesignations([]);
  //   } else {
  //     const deptId = departments.find(
  //       (dept) => dept.DepartmentName === e.target.value
  //     ).DeptID;
  //     setSelectedDept(value);
  //     getDesignations(deptId);
  //   }
  // };

  const handleDepartmentChange = (e) => {
    let value = e.target.value;
    setSelectedDept(value);
    const dept = departments.find(dept => dept.DepartmentName === value);
    if (dept) {
      getDesignations(dept.DeptID);
    } else {
      setDesignations([]);
    }
  };

  const getrequestDetails = async (eid) => {
    try {
      // Fetch request details from API
      const res = await axios.get(`${api_url}/requests/getrequestdetails/${eid}`);
      let request = res.data.data;
      console.log(request, "emp");
  
      // Fetch departments and find the department ID
      let depts = await getDepartments();
      const dept = depts.find((dept) => dept.DepartmentName === request.Department);
  
      if (dept) {
        let dept_id = dept.DeptID;
        getDesignations(dept_id);
  
        // Update state with the fetched request details
        setSelectedDept(request.Department);
        setRequestDetails(request);
      } else {
        // Handle case where department is not found
        console.warn("Department not found:", request.Department);
        setSelectedDept(""); // Clear or set to a default value
        setRequestDetails(request); // Still update with the fetched request details
      }
    } catch (error) {
      console.error("Error fetching request details:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleSubmit = async (eve) => {
    eve.preventDefault();
  
    let success = false;
  
    if (mode === "add") {
      success = await addRequest();
      console.log("success message",success)
      
    } else {
      success = await EditRequest(reqID);
    }

  
    if (success) {
      toast.success(mode === "add" ? "Request Added Successfully!" : "Request Updated Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    } else {
      toast.error(mode === "add" ? "Request Addition Failed" : "Request Update Failed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };

  const addRequest = async () => {
    const createSuccess = await InsertRequestDetails();
    console.log("createSuccess variable",createSuccess)
    if (createSuccess) {
      return true; // Employee was successfully created and updated
    }
    return false; // Indicate failure
  };
const EditRequest = async (empid) => {
  return await updateRequest(empid);
};

const getbasicdetails = async()=>{
  try{
    const response  = await axios.get(`${api_url}/user/getbasicdetails/${AtlasEmpID}`)
    if(response.data.success === true){
      let values = response.data.data;
      console.log("values",values)
      let temp = {...requestdetails}
      temp["EmpName"] = values.EmpName;
      temp["Department"] = values.Department;
      temp["Designation"] = values.Designation;
      temp["ReportingManagerName"] = values.ReportingManagerName;
      setRequestDetails(temp)
    }
  }
  catch(err){
    console.error("Error fetching details", err);
  }

} 


const RedContainedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.error.main), // Sets text color to contrast with red
  backgroundColor: theme.palette.error.main, // Sets background color to red
  "&:hover": {
    backgroundColor: theme.palette.error.dark, // Sets background color to darker red on hover
  },
}));

console.log("state value",requestdetails)

  return (
  <>{loading ? (<Loader/>):(
    <>
    <div className="d-flex justify-content-between">
        <Link to="/requests/leave-request-list" 
        // state={{ empID: empID }}
        >
          <KeyboardBackspaceIcon
             style={{
               fontWeight: "bold",
               cursor: "pointer",
               marginLeft: "5px",
             }}
           />
           <p className=" p-2 d-inline">Go back</p>
        </Link>
       </div>
       <div className="emp-form d-block">
         {/* Form Starts */}
         <form
           className=""
           onSubmit={(e) => {
             handleSubmit(e);
           }}
         >
           <div className="container-fluid">
             <div className="container p-3 form-wrapper border border-secondary">
               <div className="row sub-wrapper ">
                 <h5 className="sub-wrapper-heading">
                  Leave Request Details
                 </h5>
                 <div className="col-6">
                 <label className="form-label customlabel" htmlFor="empid">
                            AEC ID<span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="empid"
                            placeholder=""
                            disabled
                            readOnly
                            value={requestdetails.AtlasEmpID}
                            onChange={(e) => {
                              handleInputChanges(e, "AtlasEmpID");
                            }}
                          />
                 <label
                            className="form-label customlabel"
                            htmlFor="employeename"
                          >
                            Employee Name<span className="required"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="employeename"
                            placeholder=""
                            // disabled={mode === "edit"}
                            value={requestdetails.EmpName || ""}
                            maxLength="50"
                            onChange={(e) => {
                              handleInputChanges(e, "EmpName");
                            }}
                            required
                            disabled
                            readOnly
                          />
                   <label className="form-label" htmlFor="department">
                               Department<span className="required"> *</span>
                             </label>
                             <select
                               onChange={(e) => {
                                 handleInputChanges(e, "Department");
                                 handleDepartmentChange(e);
                               }}
                               value={requestdetails.Department || ""}
                               name="department"
                               className="form-input-select"
                               id="department"
                               aria-label=".form-select-sm example"
                               required
                               disabled
                            readOnly
                             >
                               <option value="">Select Department</option>
                               {departments.map((dept) => (
                                 <option
                                   key={dept.DeptID}
                                   value={dept.DepartmentName}
                                 >
                                   {dept.DepartmentName}
                                 </option>
                               ))}
                             </select>
                             <label className="form-label" htmlFor="designation">
                            Designation<span className="required"> *</span>
                          </label>
                          <select
                            className="form-input-select"
                            onChange={(e) => {
                              handleInputChanges(e, "Designation");
                            }}
                            id="designation"
                            name="designation"
                            value={requestdetails.Designation || ""}
                            // disabled={!selectedDept}
                            aria-label=".form-select-sm example"
                            required
                            disabled
                            // readOnly
                            // readOnly
                          >
                            <option value="">Select Designation</option>
                            {designations.map((item) => (
                              <option
                                key={item.DesigID}
                                value={item.DesignationName}
                              >
                                {item.DesignationName}{" "}
                              </option>
                            ))}
                          </select>
                          <label className="form-label" htmlFor="leavetype">
                            Leave Type<span className="required"> *</span>
                          </label>
                          <select
                            name="leavetype"
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                            onChange={(e) => {
                              handleInputChanges(e, "LeaveType");
                            }}
                            value={requestdetails.LeaveType || ""}
                            required
                          >
                            <option value="">Select Leave Type</option>
                            <option value="SL">Sick Leave</option>
                            <option value="AL">Annual Leave</option>
                            <option value="EL">Emergency Leave</option>
                          </select>
   
                          <label
                            className="form-label"
                            htmlFor="leavestarts"
                          >
                            Leave Starts
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="leavestarts"
                            placeholder=""
                            value={requestdetails.LeaveStarts || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "LeaveStarts");
                            }}
                          />
                           <label
                            className="form-label"
                            htmlFor="leaveends"
                          >
                            Leave Ends
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="leaveends"
                            placeholder=""
                            value={requestdetails.LeaveEnds || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "LeaveEnds");
                            }}
                          />
                           {/* <label
                            className="form-label"
                            htmlFor="submitteddate"
                          >
                            Leave Ends
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="submitteddate"
                            placeholder=""
                            value={requestdetails.SubmittedDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "SubmittedDate");
                            }}
                          /> */}
   
                   {/* <div className="m-2">
                     <div>
                       <button
                         className="btn btn-primary"
                         type="button"
                         onClick={handleUploadClick}
                       >
                         Choose File
                       </button>
                       <input
                         type="file"
                         ref={fileInputRef}
                         accept=".pdf"
                         style={{ display: "none" }}
                         onChange={(e) => {
                           handleFileChange(e);
                         }}
                       />
   
                       {mode === "edit" ? (
                         <>
                           <button
                             className="btn btn-primary ms-2"
                             type="button"
                             onClick={() => {
                               handleView(DocumentID, EmpID);
                             }}
                           >
                             View
                           </button>
                           <RedContainedButton
                             variant="contained"
                             startIcon={<DeleteIcon />}
                             onClick={() => {
                               handleDelete(EmpID,DocumentID)
                             }}
                             className="m-2"
                           >
                             Delete
                           </RedContainedButton>
                         </>
                       ) : (
                         ""
                       )}
                     </div>
                   </div> */}
                 </div>
                 <div className='col-1'></div>
                 <div className="col-2">
                 <label className="form-label customlabel" htmlFor="approvalmanager">
                            Approval Manager
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="empid"
                            placeholder=""
                            disabled
                            readOnly
                            value={requestdetails.ReportingManagerName}
                            // onChange={(e) => {
                            //   handleInputChanges(e, "AtlasEmpID");
                            // }}
                          />
                           <label className="form-label customlabel" htmlFor="hrmanager">
                            HR Manager
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="hrmanager"
                            placeholder=""
                            disabled
                            readOnly
                            value={"Rahima Nasser Al Hoqani"}
                          />

                 </div>
                 <div className='col-1 d-inline'>
                          <span className='text-success fs-6'>Approved</span>
                          <span className='text-warning fs-6' >Pending for Approval</span>
                          <span className='text-danger fs-6'>Rejected</span>
                          </div>

                          <div className='ms-5'>
               <label className="form-label customlabel" htmlFor="approvalmanager">
               Number of days:{' '}
                          </label>
               <p>
               {difference !== null ? `${difference} days` : '-'}</p>
               </div>
               </div>
               <div className="row ">
                 <div className="col d-flex justify-content-end">
                   <button type="submit" className="btn btn-primary">
                     Save
                   </button>
                 </div>
               </div>
             </div>
           </div>
         </form>
       </div>
    </>
  )}
  </> 
  );
}

export default LeaveRequestForm;