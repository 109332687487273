import React, { useEffect, useState, useRef } from "react";
import { Menu } from "../../header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { Button } from "@mui/material";
import "./EmpDocumentForm.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmpDocumentForm = () => {
  const location = useLocation();
  const { DocumentID, EmpID, mode } = location.state || {};
  const api_url = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    setSelectedFileInfo({name:"",size:""})
    fileInputRef.current.click();
  };
  const [documentDetails, setDocumentDetails] = useState({
    AtlasEmpID: EmpID,
  });

  const [documentID, setDocumentID] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileInfo, setSelectedFileInfo] = useState({
    name: "",
    size: "",
  });

  // const [isChecked, setIsChecked] = useState(true);

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleInputChanges = (event, field) => {
    let temp = { ...documentDetails };
    temp[field] = event.target.value;
    setDocumentDetails(temp);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      alert("Please choose the file");
    } else {
      const validTypes = ["application/pdf"];

      if (validTypes.includes(file.type)) {
        setSelectedFile(file);
        setSelectedFileInfo({
          name: file.name,
          size: (file.size / 1024).toFixed(2) + " KB",
        });
      } else {
        e.target.value = null;
        alert("Invalid file type. Please select an pdf file");
      }
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      getDocumentDetails(DocumentID);
    }
    // else if (mode === "add"){
    //   getLatestDocumentID();
    // }
  }, []);

  const getLatestDocumentID = async () => {
    try {
      const response = await axios.get(`${api_url}/emp/getlatestdocumentid`);
      const DocumentID = response.data[0]?.DocumentID; // Use optional chaining
      if (DocumentID) {
        setDocumentID(DocumentID);
        return DocumentID; // Return DocumentID to use in the next step
      } else {
        throw new Error("DocumentID not found in response");
      }
    } catch (error) {
      console.error("Error fetching document ID:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  const UploadEmployeeDocument = async (file, empid, documentid) => {
    try {
      const formData = new FormData();
      formData.append("DocumentID", documentid);
      formData.append("file", file);
      // console.log("DocumentID",documentid)
      // logFormData(formData);
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      await axios.post(`${api_url}/emp/uploadempdocument/${empid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error uploading signature", error);
    }
  };

  const UpdateEmployeeDocument = async (file, empid, documentid) => {
    try {
      const formData = new FormData();
      formData.append("DocumentID", documentid);
      formData.append("file", file);
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      await axios.post(
        `${api_url}/emp/updateempdocument/${empid}/${documentid}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      console.error("Error uploading signature", error);
    }
  };

  const InsertDocumentDetails = async () => {
    let temp = { ...documentDetails };
    await axios
      .post(
        `${api_url}/emp/createdocumentdetail`,
        temp, // Send the JSON object directly
        {
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      )
      .then((response) => {
        console.log("Document details created successfully", response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const handleSubmit = (eve) => {
    eve.preventDefault();
    mode === "add"
      ? addDocument()
      : EditDocument(selectedFile, EmpID, DocumentID);
  };

  const addDocument = async () => {
    try {
      await InsertDocumentDetails();

      // Get the latest DocumentID
      const latestDocumentID = await getLatestDocumentID();

      // Ensure state update is complete and use the latest DocumentID
      await UploadEmployeeDocument(selectedFile, EmpID, latestDocumentID);

      toast.success("Document Details Added Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });

      navigate("/empdocs", { state: { empID: EmpID } });
    } catch (error) {
      console.error("Error in addDocument:", error);
      toast.error("Failed to add document details.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };

  const EditDocument = async (file, empid, docid) => {
    await updateDocumentDetails(docid);
    if (selectedFile) {
      await UpdateEmployeeDocument(file, empid, docid);
    }
    toast.success("Document Details Updated Successfully!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "light",
    });
  };

  const getDocumentDetails = async (id) => {
    try {
      const res = await axios.get(`${api_url}/emp/getdocumentdetails/${id}`);
      let document = res.data[0];
      setDocumentDetails(document);
      // setIsChecked(document.StatusInd === 1); // Set checkbox state based on StatusInd
    } catch (err) {
      console.log(err);
    }
  };

  const handleView = async (Docid, AtlasEmpID) => {
    const response = await axios.post(
      "https://atlas-om.app:30000/api/emp/viewdocument",
      { Docid, AtlasEmpID }
    );
    console.log(response);
    const url = response.data;
    console.log(url);
    window.open(url, "_blank");
  };

  const updateDocumentDetails = async (eid) => {
    await axios
      .put(`${api_url}/emp/updatedocumentdetails/${eid}`, documentDetails)
      .then((response) => {
        console.log("Updated Successfully");
      })
      .catch((error) => {
        console.error("There was an error updating!", error);
      });
    navigate("/empdocs", { state: { empID: EmpID } });
  };

  const handleDelete = async (empid, docid) => {
    await axios
      .delete(`${api_url}/emp/deleterecord/${empid}/${docid}`)
      .then((res) => {
        console.log(res);
        toast.success("Document Details Deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error in Deleting Document Details!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      });
    navigate("/empdocs", { state: { empID: EmpID } });
  };

  const RedContainedButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.error.main), // Sets text color to contrast with red
    backgroundColor: theme.palette.error.main, // Sets background color to red
    "&:hover": {
      backgroundColor: theme.palette.error.dark, // Sets background color to darker red on hover
    },
  }));

  return (
    <>
      <div className="d-flex justify-content-between">
        <Link to="/empdocs" state={{ empID: EmpID }}>
          <KeyboardBackspaceIcon
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              marginLeft: "5px",
            }}
          />
          <p className=" p-2 d-inline">Go back</p>
        </Link>
      </div>
      <div className="emp-form d-block">
        {/* Form Starts */}
        <form
          className=""
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="container-fluid">
            <div className="container p-3 form-wrapper border border-secondary">
              <div className="row sub-wrapper ">
                <h5 className="sub-wrapper-heading">
                  Employee Document Details
                </h5>
                <div className="col-6">
                  <label className="form-label" htmlFor="documentcategory">
                    Document Category<span className="required"> *</span>
                  </label>
                  <select
                    onChange={(e) => {
                      handleInputChanges(e, "DocumentCategory");
                    }}
                    value={documentDetails.DocumentCategory || ""}
                    name="documentcategory"
                    className="form-input-select"
                    id="documentcategory"
                    aria-label=".form-select-sm example"
                    required
                  >
                    <option value="">Select Category</option>
                    <option value="Personal Documents">
                      Personal Documents
                    </option>
                    <option value="Passport & Civil Card Details">
                      Passport & Civil Card Details
                    </option>
                    <option value="Official Documents">
                      Official Documents
                    </option>
                    <option value="Trainings & Certificates">
                      Trainings & Certificates
                    </option>
                    <option value="Dependent Documents">
                      Dependent Documents
                    </option>
                    <option value="HR Forms">HR Forms</option>
                    <option value="Other Documents">Other Documents</option>
                  </select>
                  <label className="form-label" htmlFor="documentname">
                    Document Name<span className="required"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="documentname"
                    placeholder=""
                    value={documentDetails.DocumentName || ""}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "DocumentName");
                    }}
                    required
                  />
                  <label className="form-label" htmlFor="expirydate">
                    Expiry Date
                  </label>
                  <input
                    onChange={(e) => {
                      handleInputChanges(e, "ExpiryDate");
                    }}
                    type="date"
                    value={documentDetails.ExpiryDate || ""}
                    name="expirydate"
                    className="form-input-date"
                    id="expirydate"
                  />

                  <label className="form-label" htmlFor="description">
                    Description
                  </label>
                  <textarea
                    className="form-input-textarea"
                    id="description"
                    rows="3"
                    columns="3"
                    name="description"
                    onChange={(e) => {
                      handleInputChanges(e, "Description");
                    }}
                    value={documentDetails.Description || ""}
                  ></textarea>

                  <div className="m-2">
                    <div>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleUploadClick}
                      >
                        Choose File
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept=".pdf"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleFileChange(e);
                        }}
                      />
                      {selectedFileInfo.name && (
                        <div className="file-info">
                          <p className="form-label">
                            Selected File:
                          </p>
                          <p className="form-label d-inline">Name: </p><span className="custom-font-size">{selectedFileInfo.name}</span>
                          <div>
                          <p className="form-label d-inline">Size:</p> <span className="custom-font-size">{selectedFileInfo.size}</span>
                          </div>
                        </div>
                      )}

                      {mode === "edit" ? (
                        <>
                          <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => {
                              handleView(DocumentID, EmpID);
                            }}
                          >
                            View
                          </button>
                          <RedContainedButton
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                              handleDelete(EmpID, DocumentID);
                            }}
                            className="m-2"
                          >
                            Delete
                          </RedContainedButton>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EmpDocumentForm;
