import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";


import { Menu } from "../../header/Header";


import Button from "@mui/material/Button";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// import DisableNumberInputScroll from "../../CustomHooks/DisableNumberInputScroll";

import "./Attendance.css"

const Attendance = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const [attendanceData, setAttendanceData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const location = useLocation();
  const { PayrollID, PRYear, PRMonth } = location.state || {};
  localStorage.setItem("PayrollID",PayrollID);
  localStorage.setItem("PRMonth",PRMonth);
  localStorage.setItem("PRYear",PRYear);
  // DisableNumberInputScroll();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api_url}/payroll/attendance/GetAttendance`,{params: { PayrollID: 1 }}
        );
        setAttendanceData(response.data);
        setEditedData(
          response.data.map((emp) => ({ ...emp, PayrollID: PayrollID }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // DisableNumberInputScroll();
    fetchData();
  }, []);
 

  const handleInputChange = (index, field, value) => {
    const updatedEmployees = [...editedData];
    const employee = updatedEmployees[index];
    employee[field] = value;

    // Update PayableDays only if AB changes
    if (field === "AB") {
      //const originalPayableDays = attendanceData[index].PayableDays;
      const originalPayableDays = 30;
      const updatedPayableDays = originalPayableDays - (value || 0);
      employee.PayableDays = updatedPayableDays;
    }

    setEditedData(updatedEmployees);
  };

  const handleSaveChanges = async () => {
    // console.log("Payroll ID", PayrollID);
    try {
      // const temp = editedData.map(item => ({
      //   ...item,
      //   PayrollID: PayrollID
      // }));
      // console.log("EditedData", editedData);
      const response = await axios.put(
        `${api_url}/payroll/attendance/UpdateAttendance`,
        { data: editedData }
      );
      // console.log("Changes saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };
  const labels = [
    "S.No",
    "AEC ID",
    "Name",
    "Department",
    "Designation",
    "AB",
    "AL",
    "SL",
    "EL",
    "SPL",
    "Payable Days",
  ];


  // console.log(PayrollID);
  // console.log("Data to be saved", editedData);
  return (
    <>

      <div className=" my-1 mx-5 p-1">
        <div className="d-block overflow-auto ">
          <div className="d-inline-block align-middle ">
            <Menu link="/payroll"   />
          </div>

          <div className="d-inline-block p-1 float-xxl-end float-xl-end float-md-end float-sm-end">
            {/* <Link to="/earning" state={{ PayrollID: PayrollID,PRYear:PRYear, PRMonth:PRMonth }}> */}
            <Link to="/earning">
              <Button
                type="submit"
                variant="contained"
                onClick={handleSaveChanges}
                color="primary"
                endIcon={<ArrowForwardIcon/>}
              >
                Save & Continue
              </Button>
            </Link>
          </div>
        </div>

        <div className="table-view-container-wrapper ">
          <div className="p-2 mt-2 mb-4 fw-bold d-flex shadow justify-content-center">
            Attendance
          </div>
          <div className="">
            <table className="table table-hover table-responsive ">
              <thead>
                <tr>
                  {labels.map((item, index) => {
                    return <th scope="col" className="table-header-font-size" key={index}>{item} </th>;
                  })}
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {editedData.map((emp, index) => (
                  <tr className="py-0" scope="row" key={emp.AtlasEmpID}>
                    <td className="py-0">{index + 1}</td>
                    <td className="py-0">{emp.AtlasEmpID}</td>
                    <td className="py-0">{emp.EmpName}</td>
                    <td className="py-0">{emp.Department}</td>
                    <td className="py-0">{emp.Designation}</td>
                    <td className="py-0">
                      <input
                        type="number"
                        value={emp.AB || ""}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "AB",
                            parseInt(e.target.value) || 0
                          )
                        }
                      />
                    </td>
                    <td className="py-0">
                      <input
                        type="number"
                        value={emp.AL || ""}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "AL",
                            parseInt(e.target.value) || 0
                          )
                        }
                      />
                    </td>
                    <td className="py-0 ">
                      <input
                        type="number"
                        value={emp.SL || ""}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "SL",
                            parseInt(e.target.value) || 0
                          )
                        }
                      />
                    </td>
                    <td className="py-0">
                      <input
                        type="number"
                        value={emp.EL || ""}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "EL",
                            parseInt(e.target.value) || 0
                          )
                        }
                      />
                    </td>
                    <td className="py-0">
                      <input
                        type="number"
                        value={emp.SPL || ""}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "SPL",
                            parseInt(e.target.value) || 0
                          )
                        }
                      />
                    </td>
                    <td className="py-0">{emp.PayableDays}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendance;
