import React from "react";
import { NavLink, Link } from "react-router-dom";
import { FaTicketAlt, FaUsers } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa6";
import Logo from "../../assets/logo.png";
import "./Sidebar.css";

import { IoMdTime } from "react-icons/io";

const Sidebar = ({ isVisible, onClose }) => {
  const AtlasEmpID = localStorage.getItem("AtlasEmpID");

  return (
    <aside className={`sidebar ${isVisible ? "" : "hidden"}`}>
      <div className="sidebar-title">
        <div className="logo">
          <Link to="/hrdash">
            {/* <h2 className="mt-0 sidebar-header">ATLAS ERP</h2> */}
            <img src={Logo} alt="Logo"/>
          </Link>
        </div>
        <div className="close-button" onClick={onClose}>
          {/* Close button removed as per your requirement */}
        </div>
      </div>
      <ul className="sidebar-menu">
        {(AtlasEmpID==="AEC002" )?(<NavLink to="/hrdash" className={({ isActive }) => `sidebar-link ${isActive ? 'active' : ''}`}>
          <li>
            <FaUsers className="sidebar-icon" /> HRMS
          </li>
        </NavLink>
):""}
        
        {/* <NavLink to="/users" className="sidebar-link" activeClassName="active"> */}
        <NavLink to="/itmgmt" className={({ isActive }) => `sidebar-link ${isActive ? 'active' : ''}`}>
          <li>
            <FaTicketAlt className="sidebar-icon" /> IT
          </li>
        </NavLink>

        <NavLink to="/timesheetMgmt" className={({ isActive }) => `sidebar-link ${isActive ? 'active' : ''}`}>
          <li>
            <IoMdTime className="sidebar-icon" /> TimeSheet
          </li>
        </NavLink>

        <NavLink to="/requestdashboard" className={({ isActive }) => `sidebar-link ${isActive ? 'active' : ''}`}>
          <li>
            <FaBookmark className="sidebar-icon" /> Requests
          </li>
        </NavLink>

      </ul>
    </aside>
  );
};

export default Sidebar;
