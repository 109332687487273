import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { Menu } from "../../header/Header";

import "./Deduction.css";

const Deduction = () => {
  // const location = useLocation();
  const api_url = process.env.REACT_APP_API_URL;
  const PayrollID = +localStorage.getItem("PayrollID");
  const PRMonth = +localStorage.getItem("PRMonth");
  const PRYear = +localStorage.getItem("PRYear");
  // const { PayrollID, PRMonth, PRYear } = location.state || {};
  const [deductionList, setDeductionList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [IsEditMode, setIsEditMode] = useState(false);
  const [PayrollDeductionID, setPayrollDeductionID] = useState(0);
  const [payrollDeductionitem, setPayrollDeductionItem] = useState({
    AtlasEmpID: "",
    EmpName: "",
    EmpFullName: "",
    DeductionType: "",
    DeductionAmount: "",
    Remarks: "",
    PayrollID: PayrollID,
    PRMonth: PRMonth,
    PRYear: PRYear,
  });
  const labels = [
    "S.No",
    "Employee Name",
    "Deduction Type",
    "Deduction Amount",
    "Remarks",
    ""
  ];

  useEffect(() => {
    getDeductionList();
    getEmplist();
    if (IsEditMode === true) {
      getEmplist();
      getDeductionItem(PayrollDeductionID);
    }
  }, [IsEditMode]);

  const handleTotalDeductions = async () => {
    try {
      const response = await axios.put(
        `${api_url}/payroll/deduction/UpdateTotalDeduction`,
        {
          PayrollID: PayrollID,
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error updating totals:", error);
    }
  };

  const handleEdit = (id) => {
    getEmplist();
    console.log("Deduction Id inside Handle edit", PayrollDeductionID);
    getDeductionItem(id);
  };

  // Get list of Deduction items
  const getDeductionList = () => {
    axios
      .get(`${api_url}/payroll/deduction/DeductionList/${PayrollID}`)
      .then((res) => {
        const response = res.data;
        setDeductionList(response);
      });
  };

  //   functions used to data crud - starts
  // Getting Employee List from attendance table

  const getEmplist = () => {
    // console.log("PayrollID from GetEMpLIst",PayrollID)
    axios
      .get(`${api_url}/payroll/earning/EmpList/${PayrollID}`)
      .then((res) => {
        const response = res.data;
        setEmpList(response);
      })
      .catch((err) => console.log(err));
  };

  const resetpayrollDeductionItem = () => {
    setPayrollDeductionItem({
      AtlasEmpID: "",
      EmpName: "",
      EmpFullName: "",
      DeductionType: "",
      DeductionAmount: "",
      Remarks: "",
      PayrollID: PayrollID,
      PRMonth: PRMonth,
      PRYear: PRYear,
    });
  };

  //Getting the user entered input items
  const handleInputChange = (e, field) => {
    if (field === "DeductionAmount") {
      let temp = { ...payrollDeductionitem };
      temp[field] = Number(e.target.value);
      setPayrollDeductionItem(temp);
    } else {
      if (field === "EmpFullName") {
        let value = e.target.value; //AEC001-Name
        let parts = value.split("-");
        let id = parts[0].trim();
        let name = parts[1].trim();
        let temp = { ...payrollDeductionitem };
        temp["EmpFullName"] = e.target.value;
        temp["EmpName"] = name;
        temp["AtlasEmpID"] = id;
        setPayrollDeductionItem(temp);
        // }
      } else {
        let temp = { ...payrollDeductionitem };
        temp[field] = e.target.value;
        setPayrollDeductionItem(temp);
      }
    }
  };

  //handling the save method
  const handleSave = () => {
    axios
      .post(`${api_url}/payroll/deduction/CreateNewDeduction`, {
        data: payrollDeductionitem,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getDeductionList();
    resetpayrollDeductionItem();
  };

  const handleSubmit = (id) => {
    IsEditMode ? updateDeductionItem(id) : handleSave();
  };

  const getDeductionItem = (id) => {
    axios
      .get(`${api_url}/payroll/deduction/EditDeduction/${id}`)
      .then((res) => {
        let value = res.data[0];
        console.log("api response", res.data[0]);
        setPayrollDeductionItem((prev) => ({
          ...prev,
          AtlasEmpID: value.AtlasEmpID,
          EmpName: value.EmpName,
          EmpFullName: value.EmpFullName,
          DeductionAmount: value.DeductionAmount,
          DeductionType: value.DeductionType,
          Remarks: value.Remarks,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateDeductionItem = (id) => {
    const { DeductionType, DeductionAmount, Remarks } = payrollDeductionitem;
    let payload = {
      DeductionType: DeductionType,
      DeductionAmount: DeductionAmount,
      Remarks: Remarks,
    };
    axios
      .put(
        `${api_url}/payroll/deduction/UpdateDeduction/${id}`,
        payload
      )
      .then((res) => {
        console.log("Updated Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
    setPayrollDeductionID(0);
    getDeductionList();
    resetpayrollDeductionItem();
  };

  const handleDelete = (id) => {
    axios
      .delete(`${api_url}/payroll/deduction/DeleteDeduction/${id}`)
      .then((res) => {
        console.log("Deleted Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
    getDeductionList();
  };
  // console.log("payrollDeductionItem", payrollDeductionitem);
  // console.log(IsEditMode);
  // console.log("Deduction id", PayrollDeductionID);
  return (
    <>
      {/* <Header /> */}
      <div className=" my-1 mx-5 p-1">
        <div className="d-block overflow-auto ">
          <div className="d-inline-block align-middle">
            {/* <Menu link="/earning" PayrollID={PayrollID} PRYear={PRYear} PRMonth={PRMonth}/> */}
            <div className="">
        <Link to="/earning" state={{PayrollID:PayrollID,PRYear: PRYear, PRMonth:PRMonth}} className="text-decoration-none ">
          <KeyboardBackspaceIcon
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              marginLeft: "5px",
            }}
          />
          <p className=" p-2 d-inline">Go back</p>
        </Link>
      </div>
          </div>

          <div className="d-inline-block p-1 float-xxl-end float-xl-end float-md-end float-sm-end">
            <Link to="/finalsheet" state={{ PayrollID: PayrollID }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  handleTotalDeductions();
                }}
                endIcon={<ArrowForwardIcon />}
              >
                Save & Continue
              </Button>
            </Link>
          </div>
        </div>

        <div className="table-view-container-wrapper">
          <div className="p-2 mt-2 mb-4 d-flex fw-bold shadow justify-content-center blockquote">
            DEDUCTION
          </div>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              className=""
              variant="contained"
              color="primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              endIcon={<AddIcon />}
              onClick={() => {
                resetpayrollDeductionItem();
              }}
            >
              Add New
            </Button>
          </div>
          <div className="">
            <table className="table table-hover table-responsive ">
              <thead>
                <tr>
                  {labels.map((item, index) => {
                    return (
                      <th scope="col" key={index} className="table-header-font-size">
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {deductionList.map((item, index) => (
                  <tr
                    className="py-0"
                    scope="row"
                    key={item.PayrollDeductionID}
                  >
                    <td className="py-0">{index + 1}</td>
                    <td className="py-0">{item.EmpName}</td>
                    <td className="py-0">{item.DeductionType}</td>
                    <td className="py-0">{item.DeductionAmount}</td>
                    <td className="py-0">{item.Remarks}</td>

                    <td data-bs-toggle="modal" data-bs-target="#exampleModal">
                      <EditIcon
                        className="earning-edit-icon "
                        onClick={() => {
                          setIsEditMode(true);
                          setPayrollDeductionID(item.PayrollDeductionID);
                          handleEdit(item.PayrollDeductionID);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="modal" id="exampleModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <p>Modal body text goes here.</p>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-2">
                        <label htmlFor="earn-emp-name" className="form-label">
                          Employee
                        </label>
                      </div>
                      <div className="col-10">
                        {console.log("name", payrollDeductionitem.EmpFullName)}
                        <select
                          className="form-select"
                          id="earn-emp-name"
                          value={payrollDeductionitem.EmpFullName}
                          // disabled={IsEditMode}
                          onChange={(e) => {
                            handleInputChange(e, "EmpFullName");
                          }}
                        >
                          <option value="">Choose...</option>
                          {empList.map((item) => (
                            <option
                              key={item.AtlasEmpID}
                              value={`${item.AtlasEmpID} - ${item.EmpName}`}
                            >
                              {item.AtlasEmpID} - {item.EmpName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <label htmlFor="earn-emp-type" className="form-label">
                          Type
                        </label>
                      </div>
                      <div className="col-4">
                        <select
                          className="form-select"
                          id="earn-emp-type"
                          onChange={(e) => {
                            handleInputChange(e, "DeductionType");
                          }}
                          value={payrollDeductionitem.DeductionType || ""}
                        >
                          <option value="">Choose...</option>
                          <option value="ELA">Loans & Advance</option>
                          <option value="OD">Other Deductions</option>
                        </select>
                      </div>
                      <div className="col-2">
                        <label htmlFor="earn-emp-value" className="form-label">
                          Value
                        </label>
                      </div>
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control w-100"
                          name="earn-emp-value"
                          onChange={(e) => {
                            handleInputChange(e, "DeductionAmount");
                          }}
                          value={payrollDeductionitem.DeductionAmount}
                          id="earn-emp-value"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <label
                          htmlFor="earn-emp-remarks"
                          className="form-label"
                        >
                          Remarks
                        </label>
                      </div>
                      <div className="col-10">
                        <textarea
                          className="form-input-textarea"
                          id="earn-emp-remarks"
                          rows="3"
                          columns="6"
                          onChange={(e) => {
                            handleInputChange(e, "Remarks");
                          }}
                          value={payrollDeductionitem.Remarks}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleDelete(PayrollDeductionID);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleSubmit(PayrollDeductionID);
                    }}
                  >
                    {IsEditMode === true ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deduction;
