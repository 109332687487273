import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { Menu } from "../../header/Header";
import "./CircularForm.css";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CircularForm = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (!token) {
    navigate("/");
  }
  const [AddressTO, setAddressTO] = useState("");
  // const [cc, setCc] = useState("rahima@atlas-om.com");
  const [AddressCC, setAddressCC] = useState("raman@atlas-om.com");
  const [AddressBCC, setAddressBCC] = useState("");
  const [MailSubject, setMailSubject] = useState("");
  const [MailMessage, setMailMessage] = useState("");
  const [MailAttachments, setMailAttachments] = useState([]);

  const [departments, setSelectedDepartments] = useState([]);

 
  const handleChange = (e) => {
    let value = e.target.checked;
    console.log("event", value);
    if (value === true) {
      let temp = [...departments];
      temp.push(e.target.value);
      setSelectedDepartments(temp);
      console.log("Selected Departments", temp);
    } else {
      let temp = [...departments];
      let value = e.target.value;
      let dept = temp.includes(value);
      if (dept) {
        let index = temp.indexOf(value);
        let newarr = temp.splice(index, 1);
        setSelectedDepartments(temp);
        console.log("newarr", newarr);
        console.log("Selected Departments", temp);
      }
    }
  };

  const handleFileChange = (e) => {
    console.log("event", e);
    const files = Array.from(e.target.files);
    setMailAttachments(files);
    console.log("files", files);
  };

  const handleMailID = async () => {
    let temp = [...departments];
    let payload = {
      depts: temp,
    };
    await axios
      .post(`${api_url}/circulars/getEmailIDs`, payload)
      .then((res) => {
        let results = res.data;
        // let processeddata = results.map((item) => (item + ";"));
        setAddressTO(results);
      })
      .catch((err) => {
        console.log("Error fetching Mail ID's", err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("AddressTO", AddressTO);
    formData.append("AddressCC", AddressCC);
    formData.append("AddressBCC", AddressBCC);
    formData.append("MailSubject", MailSubject);
    formData.append("MailMessage", MailMessage);
    MailAttachments.forEach((file) => {
      formData.append("MailAttachments", file);
    });

    try {
      await axios.post(`${api_url}/circulars/sendCircular`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // alert("Email sent successfully!");
      toast.success('Email sent Successfully!', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false, 
        theme: "light",
        
      });
    } catch (error) {
      console.error("Failed to send email:", error);
    }

  };

  return (
    <>
    
      <Menu link="/circulars" />
      <div className="circular-wrapper">
        <div className="container py-3 border border-secondary circular-form-wrapper">
          <div className="row">
            <div className="col-8">
              <form onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-input-textarea text-area-field"
                    placeholder=""
                    id="to"
                    value={AddressTO}
                    onChange={(e) => setAddressTO(e.target.value)}
                  ></textarea>
                  <label className="form-label" htmlFor="to">
                    TO
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-input-text"
                    type="text"
                    placeholder="CC"
                    id="cc"
                    value={AddressCC}
                    disabled
                    onChange={(e) => setAddressCC(e.target.value)}
                  />
                  <label className="form-label" htmlFor="cc">
                    CC
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-input-text"
                    type="text"
                    placeholder="Subject"
                    value={MailSubject}
                    onChange={(e) => setMailSubject(e.target.value)}
                    required
                    subject
                  />
                  <label className="form-label" htmlFor="subject">
                    SUBJECT
                  </label>
                </div>

                <div className="form-floating mb-3">
                  <textarea
                    className="form-input-textarea text-area-field"
                    placeholder="Type Here...!"
                    id="message"
                    value={MailMessage}
                    onChange={(e) => setMailMessage(e.target.value)}
                  ></textarea>
                  <label className="form-label" htmlFor="message">
                    MESSAGE
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="formFileMultiple" className="form-label">
                    ATTACHMENTS
                  </label>
                  <input
                    onChange={handleFileChange}
                    className="form-control"
                    type="file"
                    id="formFileMultiple"
                    multiple
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}
                  >
                    Send
                  </Button>
                </div>
              </form>
            </div>
            <div className="col-4">
              <div className="select-department">
                <h5 className="sub-wrapper-heading">Select Department</h5>
                <div className="Civil">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={"Civil"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label="Civil"
                  />
                </div>
                <div className="Electrical">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={"Electrical"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label="Electrical"
                  />
                </div>
                <div className="Mechanical">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={"Mechanical"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label="Mechanical"
                  />
                </div>
                <div className="Instrumentation">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={"Instrumentation"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label="Instrumentation"
                  />
                </div>
                <div className="Piping">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={"Piping"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label="Piping"
                  />
                </div>
                <div className="Process">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={"Process"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label="Process"
                  />
                </div>
                <div className="HR">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={"HR"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label="HR"
                  />
                </div>
                <div className="IT">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={"IT"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label="IT"
                  />
                </div>
                <div className="Finance">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={"Finance"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label="Finance"
                  />
                </div>
                <div className="buttons d-flex align-items-center justify-content-start mt-4">
                  <div>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleMailID}
                    >
                      Pick
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CircularForm;
