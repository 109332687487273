import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import "./TimeManagement.css";

const TimeManagement = () => {
  const [error, setError] = useState("");
  const [punchList, setPunchList] = useState([]);

  const AtlasEmpID = "AEC006";
  const api_url = process.env.REACT_APP_API_URL;

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            setError("Unable to retrieve location.");
            reject(error);
          }
        );
      } else {
        const error = new Error(
          "Geolocation is not supported by this browser."
        );
        setError(error.message);
        reject(error);
      }
    });
  };

  const handleBaseLocationSend = async()=>{
    const location = await getCurrentLocation();
    await axios.post(`${api_url}/baselocation/sendbaselocation`,
      {userLocation: location,
        AtlasEmpID:AtlasEmpID
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      throw error;
    }
  };

  const handleValidate = async () => {
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    if (!AtlasEmpID || !currentDate) {
      alert("AtlasEmpID and PunchDate are required.");
      return false;
    }

    try {
      const response = await axios.post(
        "https://atlas-om.app:30000/api/validatecheckin",
        {
          AtlasEmpID,
          PunchDate: currentDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.IsPunchedIn === true) {
        alert("Already punched in for today.");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error during validation:", error);
      alert(
        "Error during validation: " + error.response?.data?.message ||
          error.message
      );
      return false;
    }
  };

  const handleCheckIn = async () => {
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    const currentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    try {
      const location = await getCurrentLocation();
      const ipAddress = await getIpAddress();

      const latitude = location.latitude;
      const longitude = location.longitude;
      // const latitude = 23.616846
      // const longitude = 58.247159
      if (!latitude || !longitude) {
        throw new Error("Location is not available");
      }
      if (!ipAddress) {
        throw new Error("IP Address is not available");
      }

      const isPunchedIn = await handleValidate();

      if (isPunchedIn === false) {
        const data = {
          userLocation: { latitude, longitude },
          IPAddress: ipAddress,
          AtlasEmpID: AtlasEmpID,
          Operation: "punchin",
          PunchDate: currentDate,
          PunchInTime: currentTime,
        };

        const response = await axios.post(
          `https://atlas-om.app:30000/api/createpunchdetail`,
          data
        );

        if (response.data.success) {
          alert("Check-in successful");
        } else {
          alert("Error during check-in: " + response.data.message);
        }
      }
    } catch (error) {
      console.error("Error during check-in:", error);
      alert(
        "Error during check-in: " + error.response?.data?.message ||
          error.message
      );
    }
  };

  const handleCheckOut = async () => {
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    const currentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    try {
      const location = await getCurrentLocation();
      const ipAddress = await getIpAddress();
      const { latitude, longitude } = location;
      // const latitude = 23.616846
      // const longitude = 58.247159

      if (!latitude || !longitude || !ipAddress) {
        throw new Error("Location or IP address not available");
      }

      const data = {
        userLocation: { latitude, longitude },
        IPAddress: ipAddress,
        AtlasEmpID: AtlasEmpID,
        Operation: "punchout",
        PunchDate: currentDate,
        PunchOutTime: currentTime,
      };

      const response = await axios.post(
        `https://atlas-om.app:30000/api/createpunchdetail`,
        data
      );

      if (response.data.success) {
        alert("Check-out successful");
      } else {
        alert("Error during check-out: " + response.data.message);
      }
    } catch (error) {
      console.error("Error during check-out:", error);
      alert(
        "Error during check-out: " + error.response?.data?.message ||
          error.message
      );
    }
  };

  const handlegetPunches = async () => {
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    const payload = {
      AtlasEmpID: AtlasEmpID,
      PunchDate: currentDate,
    };
    await axios
      .post(`${api_url}/punch/getlistofpunch`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setPunchList(res.data.data);
      });
  };
  useEffect(() => {
    handlegetPunches();
  }, []);

  return (
    <>
    <button type="button" onClick={handleBaseLocationSend}>Send Base Location for Approval</button>
      <div className="punch-wrapper">
        <div className="d-flex gap-3">
          {error && <p className="text-danger">{error}</p>}
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleCheckIn}
            >
              Punch In
            </Button>
          </div>
          <div>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleCheckOut}
            >
              Punch Out
            </Button>
          </div>
        </div>
        <div className="container mt-5">
          <div className="border border-secondary punch-list-header">
            <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
              Punch List - {AtlasEmpID}
            </div>
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  <th scope="col" className="table-header-font-size">
                    SNo{" "}
                  </th>
                  <th scope="col" className="table-header-font-size">
                    Punch Date{" "}
                  </th>
                  <th scope="col" className="table-header-font-size">
                    {" "}
                    in-time{" "}
                  </th>
                  <th scope="col" className="table-header-font-size">
                    {" "}
                    out-time{" "}
                  </th>
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {punchList.map((item, index) => {
                  const sno = index + 1;
                  return (
                    <tr className="py-0" key={index}>
                      <td className="py-0">{sno}</td>
                      <td className="py-0">{item.PunchDate}</td>
                      <td className="py-0">{item.PunchInTime}</td>
                      <td className="py-0">{item.PunchOutTime}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeManagement;
