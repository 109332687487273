import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { Menu } from "../../header/Header";

import "./Earning.css";

const Earning = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const PayrollID = +localStorage.getItem("PayrollID");
  const PRMonth = +localStorage.getItem("PRMonth");
  const PRYear = +localStorage.getItem("PRYear");
  const [earningList, setEarningList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [IsEditMode, setIsEditMode] = useState(false);
  const [PayrollEarningID, setPayrollEarningID] = useState(0);
  //var PayrollEarningID = 5;

  const [payrollEarningitem, setPayrollEarningItem] = useState({
    AtlasEmpID: "",
    EmpName: "",
    EmpFullName: "",
    EarningType: "",
    EarningAmount: "",
    Remarks: "",
    PayrollID: PayrollID,
    PRMonth: PRMonth,
    PRYear: PRYear,
  });
  const labels = [
    "S.No",
    "Employee Name",
    "Earning Type",
    "Earning Amount",
    "Remarks",
    ""
  ];

  useEffect(() => {
    getEarningList();
    getEmplist();
    if (IsEditMode === true) {
      getEmplist();
      getEarningItem(PayrollEarningID);
    }
  }, [IsEditMode]);

  // const calculateTotals = () => {
  //   const idMap = {};

  //   // Calculate totals for each id
  //   earningList.forEach(item => {
  //     const { AtlasEmpID, EarningAmount } = item;
  //     if (idMap[AtlasEmpID]) {
  //       idMap[AtlasEmpID] += EarningAmount;
  //     } else {
  //       idMap[AtlasEmpID] = EarningAmount;
  //     }
  //   });
  //   console.log("idMap",idMap);
  //   const totalsArray = Object.keys(idMap).map(item => ({
  //     AtlasEmpID: item,
  //     Amount: idMap[item]
  //   }));

  //   // Update state with totalsArray
  //   // settotalearningemployee(totalsArray);

  //   // Call backend API to update MySQL table
  //   handleTotalEarnings(totalsArray,PayrollID);
  // };

  const handleTotalEarnings = async () => {
    try {
      const response = await axios.put(
        `${api_url}/payroll/earning/UpdateTotalEarning`,
        {
          PayrollID: PayrollID,
        }
      );
      // console.log(response);
    } catch (error) {
      console.error("Error updating totals:", error);
    }
  };

  const handleEdit = (id) => {
    getEmplist();
    console.log("Earning Id inside Handleedit", PayrollEarningID);
    getEarningItem(id);
  };

  // Get list of earning items
  const getEarningList = () => {
    // console.log("PayrollID",PayrollID);
    // console.log("typeofpayrollid",typeof(PayrollID))
    axios   
      .get(`${api_url}/payroll/earning/EarningList/${PayrollID}`)
      .then((res) => {
        const response = res.data;
        setEarningList(response);
      });
  };

  //   functions used to data crud - starts
  // Getting Employee List from attendance table
  const getEmplist = () => {
    axios
      .get(`${api_url}/payroll/earning/EmpList/${PayrollID}`)
      .then((res) => {
        const response = res.data;
        setEmpList(response);
      })
      .catch((err) => console.log(err));
  };

  const resetpayrollEarningItem = () => {
    setPayrollEarningItem({
      AtlasEmpID: "",
      EmpName: "",
      EmpFullName: "",
      EarningType: "",
      EarningAmount: "",
      Remarks: "",
      PayrollID: PayrollID,
      PRMonth: PRMonth,
      PRYear: PRYear,
    });
  };

  //Getting the user entered input items
  const handleInputChange = (e, field) => {
    if (field === "EarningAmount") {
      let temp = { ...payrollEarningitem };
      temp[field] = Number(e.target.value);
      setPayrollEarningItem(temp);
    } else {
      if (field === "EmpFullName") {
        let value = e.target.value; //AEC001-Name
        let parts = value.split("-");
        let id = parts[0].trim();
        let name = parts[1].trim();
        let temp = { ...payrollEarningitem };
        temp["EmpFullName"] = e.target.value;
        temp["EmpName"] = name;
        temp["AtlasEmpID"] = id;
        setPayrollEarningItem(temp);
        // }
      } else {
        let temp = { ...payrollEarningitem };
        temp[field] = e.target.value;
        setPayrollEarningItem(temp);
      }
    }
  };

  //handling the save method
  const handleSave = () => {
    axios
      .post(`${api_url}/payroll/earning/CreateNewEarning`, {
        data: payrollEarningitem,
      })
      .then((res) => {
        console.log(res);
        getEarningList();
      })
      .catch((err) => {
        console.log(err);
      });
    resetpayrollEarningItem();
  };

  const handleSubmit = (id) => {
    IsEditMode ? updateEarningItem(id) : handleSave();
  };

  const getEarningItem = (id) => {
    axios
      .get(`${api_url}/payroll/earning/EditEarning/${id}`)
      .then((res) => {
        let value = res.data[0];
        console.log("api response", res.data[0]);
        // let temp = {...payrollEarningitem}                             //to get payrollid and PR month and pryear
        setPayrollEarningItem((prev) => ({
          ...prev,
          AtlasEmpID: value.AtlasEmpID,
          EmpName: value.EmpName,
          EmpFullName: value.EmpFullName,
          EarningAmount: value.EarningAmount,
          EarningType: value.EarningType,
          Remarks: value.Remarks,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateEarningItem = (id) => {
    const { EarningType, EarningAmount, Remarks } = payrollEarningitem;
    let payload = {
      EarningType: EarningType,
      EarningAmount: EarningAmount,
      Remarks: Remarks,
    };
    axios
      .put(`${api_url}/payroll/earning/UpdateEarning/${id}`, payload)
      .then((res) => {
        // console.log("Updated Successfully");
        getEarningList();
      })
      .catch((err) => {
        console.log(err);
      });
    setPayrollEarningID(0);
    
    resetpayrollEarningItem();
  };

  const handleDelete = (id) => {
    axios
      .delete(`${api_url}/payroll/earning/DeleteEarning/${id}`)
      .then((res) => {
        // console.log("Deleted Successfully");
        getEarningList();
      })
      .catch((err) => {
        console.log(err);
      });
    
  };
  // console.log(earningList);
  // console.log("payrollEarningItem", payrollEarningitem);
  // console.log(IsEditMode)
  // console.log("Earning id",PayrollEarningID)
  return (
    <>
            <div className=" my-1 mx-5 p-1">
        <div className="d-block overflow-auto ">
          <div className="d-inline-block align-middle">
            {/* <Link to="/attendance">
              <Menu state={{ PayrollID: PayrollID,PRYear:PRYear, PRMonth:PRMonth }}/>
            </Link> */}
            {/* <Menu link="/attendance" /> */}
            <div className="">
        <Link to="/attendance" state={{PayrollID:PayrollID,PRYear: PRYear, PRMonth:PRMonth}} className="text-decoration-none ">
          <KeyboardBackspaceIcon
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              marginLeft: "5px",
            }}
          />
          <p className=" p-2 d-inline">Go back</p>
        </Link>
      </div>

          </div>

          <div className="d-inline-block p-1 float-xxl-end float-xl-end float-md-end float-sm-end">
            <Link
              to="/deduction"
              state={{ PayrollID: PayrollID, PRYear: PRYear, PRMonth: PRMonth }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                  handleTotalEarnings();
                }}
              >
                Save & Continue
              </Button>
            </Link>
          </div>
        </div>


        

        <div className="table-view-container-wrapper">
          <div className="p-2 mt-2 mb-4 d-flex fw-bold shadow justify-content-center blockquote">
            EARNING
          </div>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              endIcon={<AddIcon />}
              onClick={() => {
                resetpayrollEarningItem();
              }}
            >
              Add New
            </Button>
          </div>
          <div className="">
            <table className="table table-hover table-responsive ">
              <thead>
                <tr>
                  {labels.map((item, index) => {
                    return (
                      <th scope="col" key={index} className="table-header-font-size">
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {earningList.map((item, index) => (
                  <tr className="py-0" scope="row" key={item.PayrollEarningID}>
                    <td className="py-0">{index + 1}</td>
                    <td className="py-0">{item.EmpName}</td>
                    <td className="py-0">{item.EarningType}</td>
                    <td className="py-0">{item.EarningAmount}</td>
                    <td className="py-0">{item.Remarks}</td>

                    <td data-bs-toggle="modal" data-bs-target="#exampleModal">
                      <EditIcon
                        className="earning-edit-icon "
                        onClick={() => {
                          setIsEditMode(true);
                          setPayrollEarningID(item.PayrollEarningID);
                          handleEdit(item.PayrollEarningID);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="modal" id="exampleModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <p>Modal body text goes here.</p>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-2">
                        <label htmlFor="earn-emp-name" className="form-label">
                          Employee
                        </label>
                      </div>
                      <div className="col-10">
                        {/* {console.log("name",payrollEarningitem.EmpFullName)} */}
                        <select
                          className="form-select"
                          id="earn-emp-name"
                          value={payrollEarningitem.EmpFullName}
                          // disabled={IsEditMode}
                          onChange={(e) => {
                            handleInputChange(e, "EmpFullName");
                          }}
                        >
                          <option value="">Choose...</option>
                          {empList.map((item) => (
                            <option
                              key={item.AtlasEmpID}
                              value={`${item.AtlasEmpID} - ${item.EmpName}`}
                            >
                              {item.AtlasEmpID} - {item.EmpName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <label htmlFor="earn-emp-type" className="form-label">
                          Type
                        </label>
                      </div>
                      <div className="col-4">
                        <select
                          className="form-select"
                          id="earn-emp-type"
                          onChange={(e) => {
                            handleInputChange(e, "EarningType");
                          }}
                          value={payrollEarningitem.EarningType || ""}
                        >
                          <option value="">Choose...</option>
                          <option value="OT">Over Time</option>
                          <option value="LA">Leave Salary</option>
                          <option value="SA">Salary Arrears</option>
                        </select>
                      </div>
                      <div className="col-2">
                        <label htmlFor="earn-emp-value" className="form-label">
                          Value
                        </label>
                      </div>
                      <div className="col-4">
                        <input
                          type="number"
                          className="form-control w-100"
                          name="earn-emp-value"
                          onChange={(e) => {
                            handleInputChange(e, "EarningAmount");
                          }}
                          value={payrollEarningitem.EarningAmount}
                          id="earn-emp-value"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <label
                          htmlFor="earn-emp-remarks"
                          className="form-label"
                        >
                          Remarks
                        </label>
                      </div>
                      <div className="col-10">
                        <textarea
                          className="form-input-textarea"
                          id="earn-emp-remarks"
                          rows="3"
                          columns="6"
                          onChange={(e) => {
                            handleInputChange(e, "Remarks");
                          }}
                          value={payrollEarningitem.Remarks}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleDelete(PayrollEarningID);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleSubmit(PayrollEarningID);
                    }}
                  >
                    {IsEditMode === true ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Earning;
