import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link,useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import { Menu } from "../../header/Header";
import { Checkmark } from 'react-checkmark'

const Circulars = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (!token) {
    navigate("/");
  }
    const [circulars,setCirculars] = useState([]);
    const fetchCirculars = ()=>{
        axios.get(`${api_url}/circulars/getListofCirculars`)
        .then((res)=>{
          let data = res.data.result;
          setCirculars(data)
        })
        .catch((err)=>{
          console.log(err);
        })
      }
      useEffect(() => {
        fetchCirculars();
      }, []);
  return (
    <>
      <div className=" my-1 mx-5 p-1">
        <div className="d-block overflow-auto ">
          <div className="d-inline-block align-middle">
            <Menu link="/hrdash"  />
          </div>

          <div className="d-inline-block p-1 float-xxl-end float-xl-end float-md-end float-sm-end">
            <Link to="/cir-form">
            <Button type="submit" variant="contained" color="primary" endIcon={<AddIcon/>}>
                    Send a Circular
                  </Button>
            </Link>
          </div>
        </div>
      <div className="table-view-container-wrapper">
        <div className="p-2 mt-2 mb-4 d-flex shadow fw-bold justify-content-center">
          CIRCULARS
        </div>
        <div className="">
          <table className="table table-hover table-responsive">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">To</th>
                <th scope="col">CC</th>
                <th scope="col">Subject</th>
                <th scope="col">Message</th>
                <th scope="col">Date</th>
                <th scope="col">Sent Status</th>
              </tr>
            </thead>
            <tbody className="table-body-font-size">
              {circulars.map((item, index) => (
                <tr key={index}>
                  <td className="py-0">{index + 1}</td>
                  <td className="py-0">{item.AddressTO}</td>
                  <td className="py-0">{item.AddressCC}</td>
                  <td className="py-0">{item.MailSubject}</td>
                  <td className="py-0">{item.MailMessage}</td>
                  <td className="py-0">{item.formatted_date}</td>
                  <td className="py-0">{ <Checkmark size='medium' />}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </>
  );
};

export default Circulars;
