import React from 'react';

const ITManagement = () => {
  return (
    <div>
      <h1>IT Management</h1>
      <p>Site under development</p>
    </div>
  );
};

export default ITManagement;
