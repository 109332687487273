import React, { useEffect, useState } from "react";
import { Menu } from "../../header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { Button } from "@mui/material";
import "./HRDocumentsForm.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HRDocumentsForm = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const [documentDetails, setDocumentDetails] = useState({
    StatusInd: 1, // Default value
  });
  
  const [documentID, setDocumentID] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isChecked,setIsChecked] = useState(true);


  const { DocumentID, mode } = location.state || {};

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  // const handleInputChanges = (event, field) => {
  //   let temp = { ...documentDetails };
  //   temp[field] = event.target.value;
  //   setDocumentDetails(temp);
  // };

  const handleInputChanges = (event, field) => {
    const value = event.target.value; // Trim to handle potential extra spaces
    setDocumentDetails(prevDetails => ({
      ...prevDetails,
      [field]: value === "" ? null : value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      alert("Please choose the file");
    } else {
      setSelectedFile(file);
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      getDocumentDetails(DocumentID);
    }
    // else if (mode === "add"){
    //   getLatestDocumentID();
    // }
  }, []);

  const getLatestDocumentID = async () => {
    try {
      const response = await axios.get(`${api_url}/hr/getlatestdocumentid`);
      const DocumentID = response.data[0]?.DocumentID; // Use optional chaining
      if (DocumentID) {
        setDocumentID(DocumentID);
        return DocumentID; // Return DocumentID to use in the next step
      } else {
        throw new Error("DocumentID not found in response");
      }
    } catch (error) {
      console.error("Error fetching document ID:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  // const handleDisableDocument = (e)=>{
  //   const newState = e.target.checked;
  //   setIsChecked(newState);
  // }

  const handleDisableDocument = (e) => {
    console.log("checkbox",e.target.checked);
    const newState = e.target.checked;
    setIsChecked(newState);
    setDocumentDetails((prevDetails) => ({
      ...prevDetails,
      StatusInd: newState ? 1 : 0, // Update StatusInd based on checkbox state
    }));
  };

  const UploadHRDocument = async (file, id) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("DocumentID", id); //Document ID for file naming in server
      console.log("document id before sending file", id, formData);
      // logFormData(formData);
      await axios.post(`${api_url}/hr/uploadhrdocument/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error uploading signature", error);
    }
  };

  const UpdateHRDocument = async (file, id) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("DocumentID", id); //Document ID for file naming in server
      console.log("document id before sending file", id, formData);
      // logFormData(formData);
      await axios.put(`${api_url}/hr/updatehrdocument/${id}`, formData, {
        // await axios.post(`${api_url}/hr/testendpoint/33`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error uploading signature", error);
    }
  };

  const InsertDocumentDetails = async () => {
    let temp = { ...documentDetails };
    await axios
      .post(
        `${api_url}/hr/createdocumentdetail`,
        temp, // Send the JSON object directly
        {
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      )
      .then((response) => {
        console.log("Document details created successfully", response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const handleSubmit = (eve) => {
    eve.preventDefault();
    mode === "add" ? addDocument() : EditDocument(DocumentID);
  };

  const addDocument = async () => {
    try {
      if(selectedFile){
        await InsertDocumentDetails();
        const latestDocumentID = await getLatestDocumentID();

        // Ensure state update is complete and use the latest DocumentID
        await UploadHRDocument(selectedFile, latestDocumentID);
  
        toast.success("Document Details Added Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
  
        navigate("/hrdocs");
      }
      else{
        toast.error("Please choose the document to Upload.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      }

      // Get the latest DocumentID
     
    } catch (error) {
      console.error("Error in addDocument:", error);
      toast.error("Failed to add document details.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };

  const EditDocument = async (id) => {
    await updateDocumentDetails(id);
    if (selectedFile) {
      await UpdateHRDocument(selectedFile, id);
    }
    toast.success("Document Details Updated Successfully!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "light",
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  const getDocumentDetails = async (id) => {
    try {
      const res = await axios.get(`${api_url}/hr/getdocumentdetails/${id}`);
      let document = res.data[0];
      setDocumentDetails(document);
      setIsChecked(document.StatusInd === 1); // Set checkbox state based on StatusInd
    } catch (err) {
      console.log(err);
    }
  };

  const updateDocumentDetails = async (eid) => {
    await axios
      .put(`${api_url}/hr/updatedocumentdetails/${eid}`, documentDetails)
      .then((response) => {})
      .catch((error) => {
        console.error("There was an error updating!", error);
      });
    navigate("/hrdocs");
  };

  // const handleDelete = async (cvid) => {
  //   await axios
  //     .delete(`${api_url}/recruitment/cv/deleterecord/${cvid}`)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //     navigate("/hrdocs");
  //     toast.error("Document Details Deleted!", {
  //       position: "top-center",
  //       autoClose: 2000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: false,
  //       theme: "light",
  //     });
  // };

  const RedContainedButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.error.main), // Sets text color to contrast with red
    backgroundColor: theme.palette.error.main, // Sets background color to red
    "&:hover": {
      backgroundColor: theme.palette.error.dark, // Sets background color to darker red on hover
    },
  }));

  console.log("doucment details state", documentDetails);

  return (
    <>
      <div className="d-flex justify-content-between">
        <Menu link="/hrdocs" />
       
      </div>
      <div className="emp-form d-block">
        {/* Form Starts */}
        <form
          className=""
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
           <div className="emp-form-save-button">
          {/* {mode === "edit" ? (
            <RedContainedButton
              variant="contained"
              startIcon={<DeleteIcon />}
              // onClick={() => {
              //   handleDelete(CVID);
              // }}
              className="m-2"
            >
              Delete
            </RedContainedButton>
          ) : (
            ""
          )} */}
          <Button type="submit" variant="contained" color="primary">
            Save Details
          </Button>
        </div>
          <div className="container-fluid">
            <div className="container p-3 form-wrapper border border-secondary">
              <div className="row sub-wrapper ">
                <h5 className="sub-wrapper-heading">Document Details</h5>
                <div className="col-6">
                  <label className="form-label" htmlFor="documentname">
                    Document Name<span className="required"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-input-text"
                    id="documentname"
                    placeholder=""
                    value={documentDetails.DocumentName || ""}
                    maxLength="150"
                    onChange={(e) => {
                      handleInputChanges(e, "DocumentName");
                    }}
                    required
                  />

                  <label className="form-label" htmlFor="purchasedate">
                    Purchase Date
                  </label>
                  <input
                    onChange={(e) => {
                      handleInputChanges(e, "PurchaseDate");
                    }}
                    type="date"
                    value={documentDetails.PurchaseDate || ""}
                    name="purchasedate"
                    className="form-input-date"
                    id="purchasedate"
                  />

                  <label className="form-label" htmlFor="purchasevalue">
                    Purchase Value
                  </label>
                  <input
                    onChange={(e) => {
                      handleInputChanges(e, "PurchaseValue");
                    }}
                    type="number"
                    value={documentDetails.PurchaseValue || ""}
                    name="purchasevalue"
                    className="form-input-number"
                    id="purchasevalue"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    onKeyDown={handleKeyDown}
                  />

                  <label className="form-label" htmlFor="expirydate">
                    Expiry Date
                  </label>
                  <input
                    onChange={(e) => {
                      handleInputChanges(e, "ExpiryDate");
                    }}
                    type="date"
                    value={documentDetails.ExpiryDate || ""}
                    name="expirydate"
                    className="form-input-date"
                    id="expirydate"
                  />

                  <label className="form-label" htmlFor="description">
                    Description
                  </label>
                  <textarea
                    className="form-input-textarea"
                    id="description"
                    rows="3"
                    columns="3"
                    name="description"
                    onChange={(e) => {
                      handleInputChanges(e, "Description");
                    }}
                    value={documentDetails.Description || ""}
                  ></textarea>

                  <div className="m-2">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-3">
                  
                  <input
                  className="m-2 form-check-input"
                    type="checkbox"
                    id="enabledocument"
                    checked={isChecked}
                    onChange={handleDisableDocument}
                    // disabled={isDisabled}
                  />
                  <label className="form-check-label" htmlFor="enabledocument">Enable Document</label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default HRDocumentsForm;
