import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { Menu } from "../../header/Header";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import Button from "@mui/material/Button";

import {CSVLink} from "react-csv";

const FinalSheet = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const [attendanceData, setAttendanceData] = useState([]);
  // const location = useLocation();
  // const { PayrollID } = location.state || {} || { PayrollID: "" };
  const PayrollID = +localStorage.getItem("PayrollID");

  useEffect(() => {
 
    GetNetSalary();
    fetchData();
  }, []);

  const handlePayrollHeaderInfo = ()=>{
    localStorage.removeItem("PayrollID");
    localStorage.removeItem("PRMonth");
    localStorage.removeItem("PRYear");
  }
  const GetNetSalary = async()=>{
    try{
      const response = await axios.get(`${api_url}/payroll/finalsheet/netsalary/${PayrollID}`)
      console.log(response);
    }
    catch(error){
      console.error("Error",error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${api_url}/payroll/finalsheet/getlist/${PayrollID}`);
      setAttendanceData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const labels = [
    "S.No",
    "EmpID",
    "EmpName",
    "AB",
    "AL",
    "SL",
    "EL",
    "SPL",
    "Payable Days",
    "Basic",
    "HRA",
    "TA",
    "SA",
    "CA",
    "DA",
    "FA",
    "OA",
    "Gross Salary",
    "Total Earning",
    "Total Deduction",
    "Net Salary",
  ];

  return (
    <>
      <div className=" my-1 mx-5 p-1">
        <div className="d-block overflow-auto ">
          <div className="d-inline-block align-middle">
            {/* <Menu link="/deduction" /> */}
            <div className="">
        <Link to="/deduction" state={{PayrollID:PayrollID}} className="text-decoration-none ">
          <KeyboardBackspaceIcon
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              marginLeft: "5px",
            }}
          />
          <p className=" p-2 d-inline">Go back</p>
        </Link>
      </div>
          </div>

          <div className="d-inline-block p-1 float-xxl-end float-xl-end float-md-end float-sm-end">
            <Link to="/paymgmt">
              <Button
                type="submit"
                variant="contained"
                 onClick={()=>{handlePayrollHeaderInfo()}}
                color="primary"

              >
                Continue
              </Button>
            </Link>
          </div>
        </div>

        <div className="table-view-container-fluid-wrapper">
          <div className="p-2 mt-2 mb-4 d-flex fw-bold shadow justify-content-center">
            FINAL SHEET
          </div>
          <div className="">
            <table className="table table-hover table-responsive ">
              <thead>
                <tr>
                  {labels.map((item, index) => {
                    return (
                      <th scope="col" className="table-header-font-size" key={index}>
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {attendanceData.map((emp, index) => (
                  <tr className="py-0" scope="row" key={emp.AtlasEmpID}>
                    <td className="py-0">{index + 1}</td>
                    <td className="py-0">{emp.AtlasEmpID}</td>
                    <td className="py-0">{emp.EmpName}</td>
                    {/* <td className="py-0">{emp.Department}</td>
                    <td className="py-0">{emp.Designation}</td> */}
                    <td className="py-0">{emp.AB}</td>
                    <td className="py-0">{emp.AL}</td>
                    <td className="py-0 ">{emp.SL}</td>
                    <td className="py-0">{emp.EL}</td>
                    <td className="py-0">{emp.SPL}</td>
                    <td className="py-0">{emp.PayableDays}</td>
                    <td>{emp.NetBasic}</td>
                    <td>{emp.NetHRA}</td>
                    <td>{emp.NetTA}</td>
                    <td>{emp.NetSA}</td>
                    <td>{emp.NetCA}</td>
                    <td>{emp.NetDA}</td>
                    <td>{emp.NetFA}</td>
                    <td>{emp.NetOA}</td>
                    <td>{emp.NetSalary}</td>
                    <td>{emp.TotalEarning}</td>
                    <td>{emp.TotalDeduction}</td>
                    <td>{emp.PaidSalary}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="csv-generator d-flex justify-content-center" >
            <CSVLink className="btn btn-primary" data={attendanceData} target="_blank" filename={"Final-Sheet.csv"}>Export</CSVLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinalSheet;
