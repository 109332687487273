import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Menu } from "../../header/Header";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../Loader";

import { FaEye } from "react-icons/fa6";

import "./EmployeeManagement.css";

const EmployeeManagement = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (!token) {
    navigate("/");
  }
  const [Emplist, setEmpList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading,setLoading] = useState(true);



  const [data, setDataArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const recordsPerPage = 50;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  const npage = Math.ceil(data.length / recordsPerPage);
  const numbers = [...Array(npage).keys()].map(num => num + 1);



  
  const getDepartments = async() => {
    await axios
      .get(`${api_url}/emp/GetDepartments`)
      .then((res) => {
        setDepartments(res.data.results);
      })
      .catch((error) => {
        console.error("There was an error fetching the departments!", error);
      });
  };
  const handleSearchDropdownChange = (e) => {
    let val = e.target.value;
    setSelectedDepartment(val);
    FilterEmployee(searchText, val, Emplist);
  };
  const handleSearchInput = (e) => {
    let val = e.target.value;
    setSearchText(val);
    if (selectedDepartment !== "") {
      console.log("dept inside handleseearch", selectedDepartment);
      console.log("filtered data state", filteredData);
      FilterEmployee(val, selectedDepartment, filteredData);
    } else {
      console.log("else executed");
      FilterEmployee(val, selectedDepartment, Emplist);
    }
  };
  const FilterEmployee = (search, dept, list) => {
    let filteredList = list;
  
    // Check if search text is not empty
    if (search.trim() !== "") {
      filteredList = filteredList.filter((item) => {
        // Ensure item.FirstName and item.LastName are not null or undefined
        const name = `${item.FirstName || ""} ${item.LastName || ""}`.toLowerCase();
        const keys = [
          name,
          item.AtlasEmpID || "",
          item.Department || "",
          item.Designation || "",
          item.ReportingManager || "",
          item.OfficialMobileNumber || "",
          item.OfficialEmailID || "",
        ];
  
        return keys.some((key) =>
          key.toLowerCase().includes(search.trim().toLowerCase())
        );
      });
    }
  
    // Check if department is not empty
    if (dept !== "") {
      filteredList = filteredList.filter((item) => {
        const department = item.Department || "";
        return department.toLowerCase() === dept.toLowerCase();
      });
    }
  
    // Apply both search and department filters
    if (search.trim() !== "" && dept !== "") {
      filteredList = filteredList.filter((item) => {
        const name = `${item.FirstName || ""} ${item.LastName || ""}`.toLowerCase();
        const keys = [
          name,
          item.AtlasEmpID || "",
          item.Department || "",
          item.Designation || "",
          item.ReportingManager || "",
          item.OfficialMobileNumber || "",
          item.OfficialEmailID || "",
        ];
  
        return keys.some((key) =>
          key.toLowerCase().includes(search.trim().toLowerCase())
        );
      });
    }
  
    setFilteredData(filteredList);
  };
  
  

 
  const labels = [
    "S.No",
    "AEC ID",
    "Employee Name",
    "Department",
    "Designation",
    "Reporting to",
    "Mobile Number",
    ""
  ];

  useEffect(() => {
    setDataArray(filteredData);
    setCurrentPage(1);
  }, [filteredData]);

  const nextPage = () => {
    if (currentPage < npage) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const changeCpage = (id) => {
    setCurrentPage(id);
  };


  useEffect(() => {
    const fetchData = async () => {
      // setLoading(true); // Start loading
      try {
        const res = await axios.get(`${api_url}/emp/emplist`);
        const emplist = res.data.list;
        setEmpList(emplist);
        setFilteredData(emplist);
        await getDepartments();
      } catch (error) {
        console.log(error);
        setLoading(true)
      } 
      finally {
        setLoading(false); 
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
    
     {loading ? (
      <Loader/>
     ):
     <div className="container-fluid">
          <div className="button-wrapper">
            <div>
            <Menu link="/hrdash"/>
            </div>
            <div>
              <Link to="/empform" state={{ mode: "add" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<AddIcon />}
                >
                  Add Employee
                </Button>
              </Link>
            </div>
          </div>
        {/* </div> */}
        <div className="table-view-container-wrapper">
          <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
            Employees List - ATLAS
          </div>
          <div className="employee-search-bar-wrapper d-flex justify-content-start mb-4 ">
            <div className="search-dept">
              <select
                name="department"
                className="form-input-select"
                id="department"
                aria-label="Small select example"
                onChange={(e) => {
                  handleSearchDropdownChange(e);
                }}
              >
                <option value="" defaultValue={""}>
                  Search All
                </option>
                {departments.map((dept) => (
                  <option key={dept.DeptID} value={dept.DepartmentName}>
                    {dept.DepartmentName}
                  </option>
                ))}
              </select>
            </div>
            <div className="search-field">
              <input
                type="text"
                className="form-input-text"
                placeholder="Search Employee"
                onChange={(e) => {
                  handleSearchInput(e);
                }}
              />
            </div>
          </div>
          <table className="table table-hover table-responsive">
        <thead>
          <tr>
            {labels.map((item, index) => (
              <th scope='col' key={index} className="table-header-font-size">{item} </th>
            ))}
          </tr>
        </thead>
        <tbody className='table-body-font-size'>
          {records.map((emp, index) => {
            const sno = firstIndex + index + 1;
            return (
              <tr className="py-0" key={index}>
                <td className="py-0">{sno}</td>
                <td className="py-0">{emp.AtlasEmpID}</td>
                <td className="py-0">{emp.FirstName+"  "+emp.LastName}</td>
                <td className="py-0">{emp.Department}</td>
                <td className="py-0">{emp.Designation}</td>
                <td className="py-0">{emp.ReportingManager}</td>
                <td className="py-0">{emp.OfficialMobileNumber}</td>
                <td className="py-0">
                  <Link
                    to="/empoverview"
                     state={{ mode: "edit", empID: emp.AtlasEmpID }}
                  >
                    <FaEye/>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <nav aria-label="Page navigation example" className="table-responsive mb-2">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a
              className="page-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (currentPage > 1) prevPage();
              }}
            >
              Previous
            </a>
          </li>
          {numbers.map((n) => (
            <li
              className={`page-item ${currentPage === n ? 'active' : ''}`}
              key={n}
            >
              <a
                className="page-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  changeCpage(n);
                }}
              >
                {n}
              </a>
            </li>
          ))}
          <li className={`page-item ${currentPage === npage ? 'disabled' : ''}`}>
            <a
              className="page-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (currentPage < npage) nextPage();
              }}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
        </div>
      </div>
     }
      
    </>
  );
};

export default EmployeeManagement;
