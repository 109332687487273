import React, { useState, useEffect } from "react";
import "./UserSignInForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserSignInForm = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  if (!token) {
    navigate("/");
  }

  const [userList, setUserList] = useState([]);
  const [username, setUsername] = useState("");
  const [refID,setRefID] = useState();
  const [empDetail, setEmpDetail] = useState({
    EmpID: "",
    EmpRefID: "",
    EmailID: "",
    Username:"",
    ERPLoginPassword: ""
  });

  useEffect(() => {
    const getListofUsers = async () => {
      try {
        const res = await axios.get(`${api_url}/user/fetchuserslist`);
        setUserList(res.data.list);
      } catch (err) {
        console.log("error", err);
      }
    };
    getListofUsers();
  }, [api_url]);

  const handleUsernameChange = (event) => {
    const selectedID = event.target.value;
    setUsername(selectedID);
    const selectedUser = userList.find(user => user.AtlasEmpID === selectedID);
    if (selectedUser) {
      setEmpDetail(prevState => ({
        ...prevState,
        EmpID: selectedUser.AtlasEmpID,
        Username: selectedUser.AtlasEmpID,
        EmpRefID: selectedUser.EmpRefID,
        EmailID: selectedUser.EmailID,
      }));
    }
  };

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    setEmpDetail(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };


  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const response = await axios.post(`${api_url}/user/registeruser`, empDetail, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        console.log(response.status, "response");
        toast.success("User Registered Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("User Registration Failed", error);
      toast.error("User Registration Failed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };
  

  console.log("state value", empDetail);

  return (
    <div className="d-flex justify-content-center">
      <div className="user-reg-form-wrapper">
        <form   onSubmit={(e) => {
              handleSubmit(e);
            }}>
          <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
            User Reg Form - Atlas ERP
          </div>
          <div className="reg-sub-wrapper">
            <label className="form-label customlabel" htmlFor="employee">
              AEC ID<span className="required">*</span>
            </label>
            <select
              className="form-input-select"
              id="employee"
              name="employee"
              aria-label=".form-select-sm example"
              value={username}
              onChange={handleUsernameChange}
            >
              <option value="">Select the Employee</option>
              {userList.map((item) => (
                <option key={item.AtlasEmpID} value={item.AtlasEmpID}>
                  {item.User}
                </option>
              ))}
            </select>
          </div>
          <div className="reg-sub-wrapper">
            <label className="form-label customlabel" htmlFor="username">
              UserName
            </label>
            <input
              type="text"
              className="form-input-text"
              id="username"
              value={empDetail.EmpID}
              onChange={(e) => handleInputChange(e, "EmpID")}
              placeholder=""
              disabled
              required
            />
          </div>
          <div className="reg-sub-wrapper">
            <label className="form-label customlabel" htmlFor="emailid">
              Official Email ID
            </label>
            <input
              type="email"
              className="form-input-email"
              id="emailid"
              value={empDetail.EmailID}
              placeholder=""
              onChange={(e) => handleInputChange(e, "EmailID")}
            />
          </div>
          <div className="reg-sub-wrapper">
            <label className="form-label customlabel" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              className="form-input-text"
              id="password"
              placeholder=""
              value={empDetail.ERPLoginPassword}
              onChange={(e) => handleInputChange(e, "ERPLoginPassword")}
            />
          </div>
          <div>
          <Button type="submit" variant="contained" color="primary">
                  Save Details
                </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserSignInForm;
